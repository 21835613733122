export const base = process.env.REACT_APP_API_URL;

const service = "api";
const version = "v1";
const adminPrefix = "admin";
const authPrefix = "auth";
const usersPrefix = "users";
const enterprisesPrefix = "enterprises";
const gatewayInvoices = "gateway-invoices";
const financialPrefix = "financial";
const taxesPrefix = "taxes";
const documentsPrefix = "documents";
const notificationsPrefix = "notifications";
const invoicesPrefix = "invoices";
const clientsPrefix = "clients";
const chatPrefix = "chat-messages";
const servicesPrefix = "services";
const productsPrefix = "products";
const ncmPrefix = "ncms";
const ncmCategoriesPrefix = "ncm-categories";
const cfopPrefix = "cfops";
const csosnPrefix = "csosns";
const unitMeasure = "unit-measures";
const citiesPrefix = "enotas-cities";
const couponsPrefix = "coupons";
const checkFields = "check-fields";
const cnpjPrefix = "cnpj";
const batchesPrefix = "batches";
const exportsPrefix = "exports";
const activitylogPrefix = "activitylog";
const xmlFilesPrefix = "xmlfiles";

export const baseURL = `${base}/${service}/${version}/`;

export const AUTH_USER = `${adminPrefix}/signin`;
export const REFRESH_TOKEN = `${authPrefix}/refresh-token`;
export const FORGOT_PASSWORD = `${authPrefix}/forgot-password`;
export const CHANGE_PASSWORD_WITH_TOKEN = `${authPrefix}/change-password`;

export const USERS = `${adminPrefix}/${usersPrefix}`;
export const USER_BY_ID = (id) => `${adminPrefix}/${usersPrefix}/${id}`;
export const USERS_STATUS = `${adminPrefix}/${usersPrefix}/status`;

export const ROLES = `${adminPrefix}/roles`;
export const ACTIVITIES = `${adminPrefix}/activities`;

export const ALL_AUTH_TYPES = `${adminPrefix}/authentication-types`;

export const CITIES = `/${citiesPrefix}`;
export const CITIES_UPLOAD = `${adminPrefix}/${citiesPrefix}`;

export const BILLING_RANGES = (id) => `${enterprisesPrefix}/billing-ranges/${id}`;
export const ENTERPRISE_TYPES = `${enterprisesPrefix}/types`;
export const ENTERPRISE_ACTIVITIES = `${enterprisesPrefix}/activities`;
export const ENTERPRISE_ACTIVITIES_WITH_PAGINATION = `${enterprisesPrefix}/activities/pages`;
export const ENTERPRISE_STATUS = `${adminPrefix}/${enterprisesPrefix}/status`;
export const ENTERPRISE_SUB_STATUS = `${adminPrefix}/${enterprisesPrefix}/sub-status`;
export const ENTERPRISE_SUB_STATUS_BY_STATUS = (statusId) =>
  `${adminPrefix}/${enterprisesPrefix}/sub-status/by-status/${statusId}`;
export const CREATE_ENTERPRISE = `/${enterprisesPrefix}`;
export const ENTERPRISES_ISSUANCES = `/${enterprisesPrefix}/issuances`;
export const ENTERPRISE_BY_ID = (id) => `${adminPrefix}/${enterprisesPrefix}/${id}`;
export const ENTERPRISE_AUTH_TYPES = (id) =>
  `${adminPrefix}/${enterprisesPrefix}/${id}/authentication-types`;
export const ENTERPRISE_CERTIFICATES = (id) =>
  `${adminPrefix}/${enterprisesPrefix}/${id}/certificates`;
export const ENTERPRISE_SERIAL_NUMBER = (id) =>
  `${adminPrefix}/${enterprisesPrefix}/${id}/serial-numbers`;
export const ENTERPRISE_SERVICES = (id) => `${adminPrefix}/${enterprisesPrefix}/${id}/services`;
export const ENTERPRISE_PRODUCTS = (id) =>
  `${adminPrefix}/${productsPrefix}/${enterprisesPrefix}/${id}`;
export const ENTERPRISE_NCM_CATEGORIES = (id) =>
  `${adminPrefix}/${enterprisesPrefix}/${id}/${ncmCategoriesPrefix}`;
export const ENTERPRISE_NCM = (id) => `${adminPrefix}/${enterprisesPrefix}/${id}/${ncmPrefix}`;
export const ENTERPRISE_CFOP = (id) => `${adminPrefix}/${enterprisesPrefix}/${id}/${cfopPrefix}`;
export const ENTERPRISE_CST = (id) => `${adminPrefix}/${enterprisesPrefix}/${id}/${csosnPrefix}`;
export const ENTERPRISE_PLAN_BY_ID = (id) => `${adminPrefix}/${enterprisesPrefix}/${id}/plan`;
export const ENTERPRISES_LIST = `${adminPrefix}/${enterprisesPrefix}`;
export const ENTERPRISES_LIST_BILLING = `${adminPrefix}/enterprisesbilling`;
export const ENTERPRISES_EXPORT = `${adminPrefix}/${enterprisesPrefix}/exports`;
export const ENTERPRISES_REGIMES = `${adminPrefix}/${enterprisesPrefix}/taxation-regimes`;
export const SUBSCRIPTION_PLANS_ENTERPRISE = `${enterprisesPrefix}/subscription-plans`;
export const STATUS_LOG = (id) => `${adminPrefix}/${enterprisesPrefix}/getstatuslog/${id}`;
export const ENTERPRISE_LOGS = `${adminPrefix}/${activitylogPrefix}`;
export const ENTERPRISE_XML = `${adminPrefix}/${enterprisesPrefix}/${xmlFilesPrefix}`;

export const PAYMENT_BY_TOKEN = (token) => `${enterprisesPrefix}/payments/${token}`;
export const UPDATE_PAYMENT = (id) => `${enterprisesPrefix}/payments/${id}`;
export const PAYMENT_METHODS = "/payment-methods";
export const POSTBACK_URL = (id) => `${baseURL}subscriptions/pagarme/${id}`;

export const ADDRESS_BY_CEP = (cep) => `cep/${cep}`;

export const SEND_REGISTER_EMAIL = `${enterprisesPrefix}/emailregister`;

export const TAXES = `${adminPrefix}/${taxesPrefix}`;
export const GENERAL_TAXES = `${adminPrefix}/${taxesPrefix}/common`;
export const MEI_TAXES = `${adminPrefix}/${taxesPrefix}/mei`;
export const GET_MEI_TAXES = `${taxesPrefix}/mei`;
export const SIMPLE_OR_PRESUMED = `${adminPrefix}/${taxesPrefix}/simpleOrPresumed`;

export const DOCUMENTS = `${adminPrefix}/${documentsPrefix}`;
export const DOCUMENT_BY_ID = (id) => `${adminPrefix}/${documentsPrefix}/${id}`;
export const DOCUMENTS_EXPORT = `${adminPrefix}/${documentsPrefix}/exports`;
export const DOCUMENTS_TYPES = "documentTypes";

export const INVOICE_ISSUE = `${invoicesPrefix}`;
export const INVOICES_BY_ENTERPRISE = (id) =>
  `${adminPrefix}/${invoicesPrefix}/${enterprisesPrefix}/${id}`;
export const INVOICE_DETAILS = (id) => `${adminPrefix}/${invoicesPrefix}/${id}`;
export const INVOICE_EXPORT = `${adminPrefix}/${invoicesPrefix}/exports`;
export const INVOICE_TYPES = `${adminPrefix}/invoice-types`;

export const NOTIFICATIONS = `${adminPrefix}/${notificationsPrefix}`;
export const NOTIFICATION_BY_ID = (id) => `${adminPrefix}/${notificationsPrefix}/${id}`;
export const NOTIFICATIONS_TYPES = "notificationTypes";

export const CLIENTS_LIST = `${clientsPrefix}`;
export const CLIENTS = `${adminPrefix}/${clientsPrefix}`;
export const CLIENT_BY_ID = (id) => `${adminPrefix}/${clientsPrefix}/${id}`;
export const BATCH_CLIENT_BY_ID = (id) => `${clientsPrefix}/${id}`;

export const CHATS = `${adminPrefix}/${chatPrefix}/chats`;
export const READ_CHAT_BY_ID = (id) => `${adminPrefix}/${chatPrefix}/${id}/read`;
export const CHAT_DOCUMENTS = `${adminPrefix}/${chatPrefix}/documents`;
export const MESSAGE_BY_ID = (id) => `${adminPrefix}/${chatPrefix}/${id}`;

export const CNPJ_CHECKER = (cnpj) => `${cnpjPrefix}/${cnpj}`;

export const NOTIFICATIONS_SOCKET = `/${service}/${version}/socket/${notificationsPrefix}`;
export const CHAT_SOCKET = `/${service}/${version}/socket/chat`;

export const SERVICES = `${adminPrefix}/${servicesPrefix}`;
export const SERVICE_BY_ID = (id) => `${adminPrefix}/${servicesPrefix}/${id}`;
export const SERVICES_LOCATIONS = `${adminPrefix}/service-locations`;
export const SERVICES_AVAILABLE = `${adminPrefix}/activities`;

export const PRODUCTS = `${adminPrefix}/${productsPrefix}`;
export const PRODUCT_BY_ID = (id) => `${adminPrefix}/${productsPrefix}/${id}`;
export const PRODUCTS_LOCATIONS = `${adminPrefix}/product-locations`;
export const PRODUCTS_AVAILABLE = `${adminPrefix}/activities`;

export const COUPONS = `${adminPrefix}/${couponsPrefix}`;
export const GET_COUPON = `/${couponsPrefix}`;
export const COUPOM_BY_ID = (id) => `${adminPrefix}/${couponsPrefix}/${id}`;
export const COUPONS_STATUS = `${adminPrefix}/${couponsPrefix}/status`;
export const SUBSCRIPTION_PLANS = `${adminPrefix}/subscription-plans`;

export const BATCHES = `${invoicesPrefix}/batchs`;
export const BATCHES_COMPANY = (id) => `${adminPrefix}/${invoicesPrefix}/${batchesPrefix}/${id}`;
export const BATCHES_DETAILS = (id) => `${invoicesPrefix}/${id}`;
export const BATCHES_ENTERPRISES = (id) =>
  `${adminPrefix}/${invoicesPrefix}/${batchesPrefix}/${id}/${enterprisesPrefix}`;
export const BATCHES_REISSUE = (id) => `${adminPrefix}/${invoicesPrefix}/${batchesPrefix}/${id}`;
export const BATCHES_EXPORTS = `${adminPrefix}/${invoicesPrefix}/${exportsPrefix}`;
export const BATCHES_CANCEL = (id) => `${invoicesPrefix}/${id}`;

export const NCM_GLOBALS = `${adminPrefix}/${ncmPrefix}`;
export const NCM_GLOBALS_BY_ID = (id) => `${adminPrefix}/${ncmPrefix}/${id}`;

export const NCM = `${adminPrefix}/${ncmCategoriesPrefix}`;
export const NCM_BY_ID = (id) => `${adminPrefix}/${ncmPrefix}/${id}`;

export const CFOP = `${adminPrefix}/${cfopPrefix}`;
export const CFOP_BY_ID = (id) => `${adminPrefix}/${cfopPrefix}/${id}`;

export const CST = `${adminPrefix}/${csosnPrefix}`;
export const CST_BY_ID = (id) => `${adminPrefix}/${csosnPrefix}/${id}`;

export const UNIT_MEASURE = `${adminPrefix}/${unitMeasure}`;

export const CHECK_FIELD = `/${checkFields}`;

export const PAYMENT_GATEWAYS = `${adminPrefix}/payment-gateways`;
export const SUBSCRIPTIONS = `${PAYMENT_GATEWAYS}/subscriptions`;
export const reportsPrefix = "/reports";
export const REPORTSFILES = `${reportsPrefix}/files`;
export const REPORTS_EXPORT = `${reportsPrefix}/exportfiles`;
export const PAYMENT_GATEWAY_BY_ID = (id) => `${adminPrefix}/payment-gateways/${id}`;
export const PROCESS_PAYMENT = `${enterprisesPrefix}/process-payment`;
export const CHANGE_PAYMENT_METHOD = `${enterprisesPrefix}/change-payment-method`;
export const PAYMENT_BY_BILLET = (enterpriseId) =>
  `${enterprisesPrefix}/${enterpriseId}/payments/by-billet`;
export const INVOICE_BY_TOKEN = (token) => `${enterprisesPrefix}/payment-invoice-by-token/${token}`;
export const CHARGE_INVOICE = `${enterprisesPrefix}/payment-invoice-charge`;
export const DOWNLOAD_GATEWAY_INVOICE = (filename) =>
  `${financialPrefix}/${gatewayInvoices}/download/${filename}`;
export const PREPARE_DOWNLOAD_GATEWAY_INVOICE = `${financialPrefix}/${gatewayInvoices}/prepare-download`;
