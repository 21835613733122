import * as Yup from "yup";
import { transformEmptyToUndefined } from "helpers/sanitize";

const billingReportSchema = Yup.object().shape({
  dateBegin: Yup.string()
    .transform(transformEmptyToUndefined)
    .min(6, "A data é muito curta")
    .required("Digite uma Data Inicial"),
  dateEnd: Yup.string()
    .transform(transformEmptyToUndefined)
    .when("dateBegin", {
      is: (value) => !!value,
      then: Yup.string().required("Digite uma Data Final"),
    })
    .min(6, "A data é muito curta"),
});

const billingReportFilterValues = {
  dateBegin: "",
  dateEnd: "",
};

export { billingReportSchema, billingReportFilterValues };
