import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_REJECTED,
  REQUEST_PENDING,
  REQUEST_RESOLVED,
  REQUEST_NOT_STARTED,
} from "utils/constants/request";

export const { Types: FinancialTypes, Creators: FinancialActions } = createActions({
  cleanState: null,
  getGlobalSetting: ["id"],
  getGlobalSettings: ["params"],
  getGlobalSettingsSuccess: ["payload"],
  getGlobalSettingSuccess: ["payload"],
  getInvoices: ["params"],
  getInvoicesSuccess: ["payload"],
  getFinancialInvoiceItems: ["invoiceId"],
  getFinancialInvoiceItemsSuccess: ["payload"],
  getFinancialInvoices: ["params"],
  getFinancialInvoicesSuccess: ["payload"],
  getInvoiceStatuses: null,
  getInvoiceStatusesSuccess: ["payload"],
  getPlanInvoice: ["params"],
  getPlanInvoiceSuccess: ["payload"],
  getPaymentGatewayInfo: ["id", "infoType"],
  getPaymentGatewayInfoSuccess: ["payload"],
  prepareInvoicesDownload: ["params"],
  prepareInvoicesDownloadSuccess: ["payload"],
  renewInvoice: ["enterpriseId", "invoiceId", "token"],
  renewInvoiceSuccess: ["payload"],
  requestFailure: ["error", "originalType"],
  setGlobalSetting: ["payload"],
  setGlobalSettingSuccess: ["payload"],
  getReportBilling: ["params"],
  getReportBillingSuccess: ["payload"],
});

const listType = {
  items: [],
};

const INITIAL_STATE = {
  downloadData: {},
  error: null,
  globalSetting: {},
  globalSettings: listType,
  invoices: listType,
  invoiceItems: null,
  invoiceStatuses: listType,
  paymentGatewayInfo: {},
  planInvoice: null,
  renewedInvoice: {},
  reportBilling: {},
  requestStatus: {
    [FinancialTypes.GET_PAYMENT_GATEWAY_INFO]: REQUEST_NOT_STARTED,
    [FinancialTypes.GET_GLOBAL_SETTING]: REQUEST_NOT_STARTED,
    [FinancialTypes.GET_GLOBAL_SETTINGS]: REQUEST_NOT_STARTED,
    [FinancialTypes.GET_FINANCIAL_INVOICE_ITEMS]: REQUEST_NOT_STARTED,
    [FinancialTypes.GET_INVOICE_STATUSES]: REQUEST_NOT_STARTED,
    [FinancialTypes.GET_INVOICES]: REQUEST_NOT_STARTED,
    [FinancialTypes.PREPARE_INVOICES_DOWNLOAD]: REQUEST_NOT_STARTED,
    [FinancialTypes.RENEW_INVOICE]: REQUEST_NOT_STARTED,
    [FinancialTypes.SET_GLOBAL_SETTING]: REQUEST_NOT_STARTED,
    [FinancialTypes.GET_FIN_SUBS]: REQUEST_NOT_STARTED,
    [FinancialTypes.GET_PLAN_INVOICE]: REQUEST_NOT_STARTED,
    [FinancialTypes.GET_REPORT_BILLING]: REQUEST_NOT_STARTED,
  },
};

const cleanState = () => INITIAL_STATE;

const getGlobalSetting = (state, { id }) => ({
  ...state,
  id,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.GET_GLOBAL_SETTING]: REQUEST_PENDING,
  },
});

const getGlobalSettingSuccess = (state, { payload }) => ({
  ...state,
  globalSetting: payload,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.GET_GLOBAL_SETTING]: REQUEST_RESOLVED,
  },
});

const getGlobalSettings = (state, { params }) => ({
  ...state,
  params,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.GET_GLOBAL_SETTINGS]: REQUEST_PENDING,
  },
});

const getGlobalSettingsSuccess = (state, { payload }) => ({
  ...state,
  globalSettings: payload,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.GET_GLOBAL_SETTINGS]: REQUEST_RESOLVED,
  },
});

const getInvoices = (state, { params }) => ({
  ...state,
  params,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.GET_INVOICES]: REQUEST_PENDING,
  },
});

const getInvoicesSuccess = (state, { payload }) => ({
  ...state,
  invoices: payload,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.GET_INVOICES]: REQUEST_RESOLVED,
  },
});

const getFinancialInvoices = (state, { params }) => ({
  ...state,
  params,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.GET_FINANCIAL_INVOICES]: REQUEST_PENDING,
  },
});

const getFinancialInvoicesSuccess = (state, { payload }) => ({
  ...state,
  invoices: payload,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.GET_FINANCIAL_INVOICES]: REQUEST_RESOLVED,
  },
});

const getFinancialInvoiceItems = (state, { params }) => ({
  ...state,
  params,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.GET_FINANCIAL_INVOICE_ITEMS]: REQUEST_PENDING,
  },
});

const getFinancialInvoiceItemsSuccess = (state, { payload }) => ({
  ...state,
  invoiceItems: payload,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.GET_FINANCIAL_INVOICE_ITEMS]: REQUEST_RESOLVED,
  },
});

const getInvoiceStatuses = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.GET_INVOICE_STATUSES]: REQUEST_PENDING,
  },
});

const getInvoiceStatusesSuccess = (state, { payload }) => ({
  ...state,
  invoiceStatuses: payload,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.GET_INVOICE_STATUSES]: REQUEST_RESOLVED,
  },
});

const getPlanInvoice = (state) => ({
  ...state,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.GET_PLAN_INVOICE]: REQUEST_PENDING,
  },
});

const getPlanInvoiceSuccess = (state, { payload }) => ({
  ...state,
  planInvoice: payload,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.GET_PLAN_INVOICE]: REQUEST_RESOLVED,
  },
});

const getPaymentGatewayInfo = (state, { id, infoType }) => ({
  ...state,
  id,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.GET_PAYMENT_GATEWAY_INFO]: REQUEST_PENDING,
  },
});

const getPaymentGatewayInfoSuccess = (state, { payload }) => ({
  ...state,
  paymentGatewayInfo: payload,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.GET_PAYMENT_GATEWAY_INFO]: REQUEST_RESOLVED,
  },
});

const prepareInvoicesDownload = (state, { params }) => ({
  ...state,
  params,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.PREPARE_INVOICES_DOWNLOAD]: REQUEST_PENDING,
  },
});

const prepareInvoicesDownloadSuccess = (state, { payload }) => ({
  ...state,
  downloadData: payload,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.PREPARE_INVOICES_DOWNLOAD]: REQUEST_RESOLVED,
  },
});

const renewInvoice = (state, { enterpriseId, invoiceId, token }) => ({
  ...state,
  enterpriseId,
  invoiceId,
  token,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.RENEW_INVOICE]: REQUEST_PENDING,
  },
});

const renewInvoiceSuccess = (state, { payload }) => ({
  ...state,
  renewedInvoice: payload,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.RENEW_INVOICE]: REQUEST_RESOLVED,
  },
});

const requestFailure = (state, { error, originalType }) => ({
  ...state,
  error,
  requestStatus: {
    ...state.requestStatus,
    [originalType]: REQUEST_REJECTED,
  },
});

const setGlobalSetting = (state, { payload }) => ({
  ...state,
  payload,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.SET_GLOBAL_SETTING]: REQUEST_PENDING,
  },
});

const setGlobalSettingSuccess = (state, { payload }) => ({
  ...state,
  globalSetting: payload,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.SET_GLOBAL_SETTING]: REQUEST_RESOLVED,
  },
});

const getReportBilling = (state, { params }) => ({
  ...state,
  params,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.GET_REPORT_BILLING]: REQUEST_PENDING,
  },
});

const getReportBillingSuccess = (state, { payload }) => ({
  ...state,
  reportBilling: payload,
  requestStatus: {
    ...state.requestStatus,
    [FinancialTypes.GET_REPORT_BILLING]: REQUEST_RESOLVED,
  },
});

export default createReducer(INITIAL_STATE, {
  [FinancialTypes.CLEAN_STATE]: cleanState,
  [FinancialTypes.GET_GLOBAL_SETTING]: getGlobalSetting,
  [FinancialTypes.GET_GLOBAL_SETTING_SUCCESS]: getGlobalSettingSuccess,
  [FinancialTypes.GET_GLOBAL_SETTINGS]: getGlobalSettings,
  [FinancialTypes.GET_GLOBAL_SETTINGS_SUCCESS]: getGlobalSettingsSuccess,
  [FinancialTypes.GET_INVOICES]: getInvoices,
  [FinancialTypes.GET_INVOICES_SUCCESS]: getInvoicesSuccess,
  [FinancialTypes.GET_FINANCIAL_INVOICE_ITEMS]: getFinancialInvoiceItems,
  [FinancialTypes.GET_FINANCIAL_INVOICE_ITEMS_SUCCESS]: getFinancialInvoiceItemsSuccess,
  [FinancialTypes.GET_FINANCIAL_INVOICES]: getFinancialInvoices,
  [FinancialTypes.GET_FINANCIAL_INVOICES_SUCCESS]: getFinancialInvoicesSuccess,
  [FinancialTypes.GET_INVOICE_STATUSES]: getInvoiceStatuses,
  [FinancialTypes.GET_INVOICE_STATUSES_SUCCESS]: getInvoiceStatusesSuccess,
  [FinancialTypes.GET_PLAN_INVOICE]: getPlanInvoice,
  [FinancialTypes.GET_PLAN_INVOICE_SUCCESS]: getPlanInvoiceSuccess,
  [FinancialTypes.GET_PAYMENT_GATEWAY_INFO]: getPaymentGatewayInfo,
  [FinancialTypes.GET_PAYMENT_GATEWAY_INFO_SUCCESS]: getPaymentGatewayInfoSuccess,
  [FinancialTypes.PREPARE_INVOICES_DOWNLOAD]: prepareInvoicesDownload,
  [FinancialTypes.PREPARE_INVOICES_DOWNLOAD_SUCCESS]: prepareInvoicesDownloadSuccess,
  [FinancialTypes.RENEW_INVOICE]: renewInvoice,
  [FinancialTypes.RENEW_INVOICE_SUCCESS]: renewInvoiceSuccess,
  [FinancialTypes.REQUEST_FAILURE]: requestFailure,
  [FinancialTypes.SET_GLOBAL_SETTING]: setGlobalSetting,
  [FinancialTypes.SET_GLOBAL_SETTING_SUCCESS]: setGlobalSettingSuccess,
  [FinancialTypes.GET_REPORT_BILLING]: getReportBilling,
  [FinancialTypes.GET_REPORT_BILLING_SUCCESS]: getReportBillingSuccess,
});
