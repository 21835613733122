import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "components/core/Modal";
import { billingReportSchema } from "utils/schemas/billingReport";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { MASK_TYPES } from "utils/masks";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LINKS } from "utils/constants";
import { useRequest } from "hooks";
import { LayoutActions } from "store/ducks/layout";
import { Span } from "components/core";
import { useDispatch, useSelector } from "react-redux";
import { FinancialActions } from "store/ducks/financial";
import { UsersActions } from "store/ducks/users";
import { getAuthClaims } from "helpers";
import { Flex } from "../Grid";
import { Form, InputGroup } from "../Form";
import InputForm from "../InputForm";
import { Label } from "../InputForm/styled";
import { ModalActions } from "../Modal/styled";
import Button from "../Button";

const BillingModal = ({ validationSchema, handleNewSubmit, handleCancelClick }) => {
  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    mode: "onBlur",
    submitFocusError: true,
    nativeValidation: false,
  });

  return (
    <Flex verticalAlign="center">
      <Form onSubmit={handleSubmit(handleNewSubmit)}>
        <Label>Período:</Label>
        <InputGroup paddingLeft="0" columns="2">
          <InputForm
            register={register}
            type="text"
            name="dateBegin"
            label="De:"
            direction="row"
            horizontalAlign="center"
            verticalAlign="center"
            labelDark
            errorMsg={errors.dateBegin?.message}
            mask={MASK_TYPES.dateMonth}
          />
          <InputForm
            register={register}
            type="text"
            name="dateEnd"
            label="Até:"
            direction="row"
            horizontalAlign="center"
            verticalAlign="center"
            labelDark
            errorMsg={errors.dateEnd?.message}
            mask={MASK_TYPES.dateMonth}
          />
        </InputGroup>
        <ModalActions>
          <Button variant="outline" handleClick={handleCancelClick}>
            Cancelar
          </Button>
          <Button type="submit">Gerar Relatório</Button>
        </ModalActions>
      </Form>
    </Flex>
  );
};

BillingModal.propTypes = {
  validationSchema: PropTypes.object.isRequired,
  handleNewSubmit: PropTypes.func.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
};

const ReportBillingModal = ({ nameEnterprise, data, showModal, setShowModal }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dates, setDates] = useState({});
  const { userDetails } = useSelector(({ users }) => users);
  const { requestStatus, reportBilling } = useSelector(({ financial }) => financial);

  const {
    user: { id: idUser },
  } = getAuthClaims();

  const dispatchGetUser = useCallback(
    () => dispatch(UsersActions.getUserById(idUser)),
    [dispatch, idUser],
  );

  useEffect(() => {
    dispatchGetUser();
  }, [dispatchGetUser]);

  const dispatchResult = (text, type) =>
    dispatch(
      LayoutActions.showConfirmationModal({
        content: <Span>{text}</Span>,
        type,
      }),
    );

  useRequest({
    request: requestStatus.GET_REPORT_BILLING,
    onResolved: () => {
      const { enterpriseName, number, street, city, cnpj } = data;

      const total = reportBilling.reduce((acc, act) => acc + parseFloat(act.sum), 0);
      const media = total / reportBilling.length;

      history.push({
        pathname: LINKS.pageBillingReport,
        state: {
          userDetails,
          dataReport: {
            enterpriseName,
            number,
            street,
            city,
            cnpj,
            reportBilling,
            total,
            media,
            dates,
          },
        },
      });
    },
    onRejected: () => {
      dispatchResult("Erro ao gerar relatório!", "error");
    },
  });

  const handleSubmit = ({ dateBegin, dateEnd }) => {
    const { id } = data;

    const separateDateBegin = dateBegin.split("/");
    const monthDateBegin = separateDateBegin[0].padStart(2, "0");
    const yearDateBegin = separateDateBegin[1];

    const newDateBegin = `${yearDateBegin}-${monthDateBegin}-01`;

    const separateDateEnd = dateEnd.split("/");
    const monthDateEnd = separateDateEnd[0].padStart(2, "0");
    const yearDateEnd = separateDateEnd[1];

    const newDateEnd = `${yearDateEnd}-${monthDateEnd}-31`;

    setDates({ dateBegin, dateEnd });
    dispatch(FinancialActions.getReportBilling({ id, newDateBegin, newDateEnd }));
  };

  return (
    <Modal
      title={`Relatório Faturamento - ${nameEnterprise}`}
      confirmLabel="Gerar"
      onCloseModal={() => setShowModal(false)}
      showModal={showModal}
      textAlign="text-left"
      showActions={false}
      width="35%"
    >
      <BillingModal
        handleNewSubmit={handleSubmit}
        validationSchema={billingReportSchema}
        setShowModal={showModal}
        handleCancelClick={() => setShowModal(false)}
      />
    </Modal>
  );
};

export default ReportBillingModal;
