import React from "react";
import PropTypes from "prop-types";
import handleInputMask, { MASK_TYPES } from "utils/masks";

import * as S from "./styled";

const InputForm = ({
  errorMsg,
  label,
  name,
  children,
  mask,
  direction,
  horizontalAlign,
  verticalAlign,
  labelDark,
  register,
  maskOn,
  marginBottom,
  marginRight,
  content,
  isLoading,
  ...otherProps
}) => (
  <S.InputWrapper
    direction={direction}
    horizontalAlign={horizontalAlign}
    verticalAlign={verticalAlign}
    marginBottom={marginBottom}
    marginRight={marginRight}
    content={content}
    isLoading={isLoading}
  >
    <S.Label htmlFor={name} hasError={!!errorMsg} labelDark={labelDark}>
      {label}
    </S.Label>
    <S.Field
      ref={register}
      id={name}
      name={name}
      hasError={!!errorMsg}
      onInput={(event) => maskOn && handleInputMask(event, mask)}
      isLoading={isLoading}
      {...otherProps}
    />
    {!!errorMsg && <S.ErrorMsg data-testid="errorMsg">{errorMsg}</S.ErrorMsg>}
    {children}
  </S.InputWrapper>
);

InputForm.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
  mask: PropTypes.oneOf(
    Object.keys(MASK_TYPES),
    //   [
    //   "cep",
    //   "cpf",
    //   "char",
    //   "cnpj",
    //   "date",
    //   "dateWithLine",
    //   "real",
    //   "phone",
    //   "number",
    //   "phoneNoDDD",
    //   "realWithName",
    //   "numberWithComma",
    //   "numberWithDot",
    // ]
  ),
  direction: PropTypes.string,
  horizontalAlign: PropTypes.string,
  verticalAlign: PropTypes.string,
  labelDark: PropTypes.bool,
  register: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  maskOn: PropTypes.bool,
  marginBottom: PropTypes.string,
  content: PropTypes.string,
  isLoading: PropTypes.bool,
};

InputForm.defaultProps = {
  errorMsg: null,
  children: null,
  mask: null,
  direction: null,
  horizontalAlign: null,
  verticalAlign: null,
  labelDark: false,
  register: null,
  maskOn: true,
  marginBottom: null,
  content: null,
  isLoading: false,
};

export default InputForm;
