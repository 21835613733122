import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import {
  RenderHeaders,
  Table,
  TableHeader,
  TableBody,
  TableItem,
  TableCheckbox,
  TableHeaderItem,
} from "components/core/Table";
import Pagination from "components/core/Pagination";
import StatusDot, { StatusTypes } from "components/core/StatusDot";
import { COLORS } from "utils/constants";
import * as S from "./../styled";
import { Spinner, SpinnerOverlay } from "components/core/WithSpinner/styled";
import { isResolved } from "components/core/WithSpinner";
import { MASK_TYPES, format } from "utils/masks";
import Modal from "components/core/Modal";
import { Span } from "components/core/Typography";
import { Columns, Container, Flex, ColumnFlex, RowFlex } from "components/core/Grid";
import CardInfo from "components/core/CardInfo";
import { formatDate } from "helpers";
import { useParams } from "react-router-dom";
import { useFinancialSubscription } from "hooks";
import Button from "components/core/Button";
import { Label } from "components/core/InputForm/styled";
import InputForm from "components/core/InputForm";
import { REQUEST_NOT_STARTED, REQUEST_PENDING } from "utils/constants/request";
import moment from "moment/moment";
import InvoiceEmailTemplate from "components/presentation/Financial/Templates/EmailInvoice";
import EmailModal from "components/presentation/Financial/Utils/EmailModal";
import { Chrevon } from "./../icons";

const List = ({
  items,
  total,
  totalPages,
  params,
  page,
  perPage,
  orderBy,
  order,
  translate,
  toggleAll,
  everyoneIsChecked,
  handleChange,
  subscriptionInvoice,
  getSubscriptionInvoice,
  requestStatusInvoice,
  handleMail,
  showModalMail,
  setShowModalMail,
  mailParams,
  sendMail,
}) => {
  const {
    resetRequestState,
    resetState,
    cancelInvoice,
    handleOnResolved,
    subscription: { billingDate },
    requestStatus,
  } = useFinancialSubscription();
  const [linkCopied, setLinkCopied] = useState(false);
  const [showItems, setShowItems] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showConfirmCancelInvoice, setShowConfirmCancelInvoice] = useState(false);
  const [billSubscription, setBillSubscription] = useState(true);
  const [nextBillingDate, setNextBillingDate] = useState(null);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [selectedInvoiceBilling, setSelectedInvoiceBilling] = useState(null);
  const [selectedInvoiceBillingPeriod, setSelectedInvoiceBillingPeriod] = useState(null);
  const [emails, setEmails] = useState("");
  const [mailMessage, setMailMessage] = useState(null);
  const [mailSubject, setMailSubject] = useState(null);
  const [mailGreetings, setMailGreetings] = useState("");
  const [showHistory, setShowHistory] = useState(false);
  const [showChargeDetails, setShowChargeDetails] = useState(null);
  const [selectedCharge, setSelectedCharge] = useState(null);

  const itemPadding = "0.75rem 1rem";

  const headersCharges = [
    { slug: "id", title: "ID" },
    { slug: "createdAt", title: `${translate("Date")}` },
    { slug: "userName", title: `${translate("User")}` },
    { slug: "lr", title: `${translate("LR")}` },
    { slug: "status", title: `${translate("Status")}` },
    { slug: "actions", title: `${translate("Actions")}` },
  ];

  const { id: subscriptionId } = useParams();
  const { CANCEL_INVOICE, SEND_FINANCIAL_INVOICE_MAIL } = requestStatus;
  const headers = [
    { slug: "id", title: "ID" },
    { slug: "status", title: `${translate("status")}` },
    { slug: "dueDate", title: translate("Due Date"), textAlign: "center" },
    { slug: "billingPeriod", title: translate("Reference"), textAlign: "center" },
    { slug: "total", title: translate("Value"), textAlign: "center" },
    { slug: "paymentDate", title: `${translate("Payment Date")}`, textAlign: "center" },
    { slug: "paymentPaidAmount", title: translate("Payment Value"), textAlign: "center" },
    { slug: "actions", title: `${translate("Actions")}` },
  ];
  const headersItems = [
    { slug: "description", title: `${translate("description")}` },
    { slug: "price", title: `${translate("Value")}`, textAlign: "center" },
    { slug: "quantity", title: `${translate("Quantity")}`, textAlign: "center" },
    { slug: "total", title: `${translate("Total")}`, textAlign: "center" },
  ];
  const colPadding = "0.75rem";
  const parseInvoiceLink = (invoiceToken) => {
    return `${window.location.origin}/invoice-payment/${invoiceToken}`;
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return COLORS.financialPendingColor;
      case "paid":
        return COLORS.financialPaidColor;
      case "overdue":
        return COLORS.financialOverDueColor;
      case "processingPayment":
        return COLORS.financialOtherColor;
      case "canceled":
        return COLORS.financialOtherColor;
      case "refunded":
        return COLORS.financialRefoundedColor;
      default:
        return StatusTypes.INFO;
    }
  };

  useEffect(() => {
    if (
      subscriptionInvoice &&
      subscriptionInvoice?.invoiceCharges?.length > 0 &&
      subscriptionInvoice?.invoiceCharges?.length <= 5
    ) {
      setShowHistory(true);
    }
  }, [subscriptionInvoice]);

  useEffect(() => {
    if (mailParams) {
      const emails = `${mailParams.email},`;
      setEmails(emails);
    }
  }, [mailParams]);

  useEffect(() => {
    if (isResolved(SEND_FINANCIAL_INVOICE_MAIL)) {
      setMailMessage(null);
      setMailSubject(null);
      setEmails(null);
      setShowModalMail(false);
      resetRequestState("SEND_FINANCIAL_INVOICE_MAIL");
      handleOnResolved("E-mail enviado com sucesso");
    }
  }, [SEND_FINANCIAL_INVOICE_MAIL]);

  useEffect(() => {
    const date = moment(moment(billingDate).hours(0).minutes(0).seconds(0).milliseconds(0));
    const today = moment(moment().hours(0).minutes(0).seconds(0).milliseconds(0));
    const _billingDate = today.isBefore(date)
      ? billingDate
      : today.add(1, "day").format("YYYY-MM-DD");
    setNextBillingDate(_billingDate);
  }, [billingDate]);

  const handleViewItems = (invoiceId) => {
    getSubscriptionInvoice({ invoiceId, subscriptionId });
    setShowItems(true);
  };

  const handleViewDetails = (invoiceId) => {
    getSubscriptionInvoice({ invoiceId, subscriptionId });
    setShowHistory(false);
    setShowDetails(true);
  };

  const submitCancelInvoice = () => {
    cancelInvoice({
      invoiceId: selectedInvoiceId,
      subscriptionId: +subscriptionId,
      billSubscription,
    });
  };

  const toggleShowHistory = () => setShowHistory(!showHistory);

  const handleClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 3000);
  };

  const handleTab = (url) => {
    window.open(url, "_blank");
  };

  const handleCancelInvoice = (invoice) => {
    setSelectedInvoiceId(invoice.id);
    setSelectedInvoiceBilling(invoice.billing);
    setSelectedInvoiceBillingPeriod(invoice.billingPeriod);
    setShowConfirmCancelInvoice(true);
  };

  const openModalMail = (invoiceId) => {
    const invoice = items.find((item) => item.id === invoiceId);
    setSelectedInvoiceId(invoice?.id);
    handleMail(invoice);
  };

  const submitMail = () => {
    sendMail(mailSubject, mailMessage, mailGreetings, emails);
  };

  const isPending = (status) => {
    const stats = status === REQUEST_NOT_STARTED || status === REQUEST_PENDING;
    return stats;
  };

  const closeModal = (callback) => {
    if (callback) callback(false);
    resetState({ stateName: "subscriptionInvoice" });
    setSelectedInvoiceId(null);
    setSelectedInvoiceBilling(null);
    setSelectedInvoiceBillingPeriod(null);
    setShowConfirmCancelInvoice(false);
  };

  const getRealMask = (price) =>
    price >= 0 ? MASK_TYPES.realWithName : MASK_TYPES.negativeRealWithName;

  const getStyles = (price) => (price >= 0 ? { color: "blue" } : { color: "red" });

  const handleViewDetailsChargeHistory = (chargeId) => {
    const charge = subscriptionInvoice.invoiceCharges.find((item) => item.id === chargeId);
    setSelectedCharge(charge);
    setShowChargeDetails(true);
  };

  const closeModalChargeDetails = () => {
    setSelectedCharge(null);
    setShowChargeDetails(false);
  };

  const billingPeriod = () => {
    const billing = selectedInvoiceBilling;
    if (!billing || !billing?.expiresAt || !billing?.billingPeriod) return "";
    const expiresAtDate = moment(billing.expiresAt).add(1, "day").format("DD");
    return moment(billing?.billingPeriod)
      .date(expiresAtDate)
      .format(billing.paymentGatewayPlanTypeId === 1 ? "MM/YYYY" : "YYYY");
  };

  const formatPeriod = (period) => {
    if (!period) return <Span>-</Span>;
    return moment(period).format("MM/YYYY");
  };

  return (
    <>
      <Table>
        <TableHeader>
          <TableHeaderItem alignCenter width="5%">
            <TableCheckbox
              name="toggleAllInvoices"
              handleChange={toggleAll}
              checked={everyoneIsChecked}
            />
          </TableHeaderItem>
          <RenderHeaders
            headers={headers}
            setOrderBy={params.setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {items.map(
            ({
              id,
              status,
              dueDate,
              paymentDate,
              paymentPaidAmount,
              total,
              gatewayInvoiceId,
              checked,
              emailSent,
              billing,
              billingPeriod,
            }) => (
              <tr key={id}>
                <TableItem padding={colPadding}>
                  <TableCheckbox
                    name={`toggle_${id}`}
                    value={id}
                    handleChange={() => handleChange(id)}
                    checked={checked}
                  />
                </TableItem>
                <TableItem padding={colPadding}>{id}</TableItem>
                <TableItem padding={colPadding}>
                  <Span data-cy="status" style={{ cursor: "pointer" }}>
                    <StatusDot type={getStatusColor(status)} />
                    {translate(status)}
                  </Span>
                </TableItem>
                <TableItem padding={colPadding} textAlign="center">
                  {formatDate(dueDate, 0, false)}
                </TableItem>
                <TableItem padding={colPadding} textAlign="center">
                  {formatPeriod(billingPeriod)}
                </TableItem>
                <TableItem padding={colPadding} textAlign="right">
                  {format(Number(total).toFixed(2), MASK_TYPES.realWithName)}
                </TableItem>
                <TableItem padding={colPadding} textAlign="center">
                  {paymentDate ? formatDate(paymentDate, 0, false) : <Span>-</Span>}
                </TableItem>
                <TableItem padding={colPadding} textAlign="center">
                  {paymentPaidAmount ? (
                    format(Number(paymentPaidAmount).toFixed(2), MASK_TYPES.realWithName)
                  ) : (
                    <Span>-</Span>
                  )}
                </TableItem>
                <TableItem padding={colPadding}>
                  <RowFlex>
                    {/* <S.IconEdit title="Editar item" style={{ cursor: "pointer" }} onClick={() => {}} />
                <S.IconDelete
                  style={{ cursor: "pointer" }}
                  onClick={() => {}}
                  title="Remover item"
                /> */}
                    {status === "pending" && (
                      <S.IconCopy
                        title="Copiar link"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleClipboard(parseInvoiceLink(gatewayInvoiceId))}
                      />
                    )}
                    <S.IconView
                      style={{ cursor: "pointer" }}
                      onClick={() => handleViewDetails(id)}
                      title="Ver detalhes"
                    />
                    <S.IconItems
                      style={{ cursor: "pointer" }}
                      onClick={() => handleViewItems(id)}
                      title="Ver Itens da Fatura"
                    />
                    {status === "pending" && (
                      <>
                        <S.IconMail
                          fill={emailSent ? "#04e762" : "#0071ee"}
                          style={{ cursor: "pointer" }}
                          onClick={() => openModalMail(id)}
                          title="Enviar e-mail"
                        />
                        <S.IconClose
                          style={{ cursor: "pointer", color: "red" }}
                          onClick={() => handleCancelInvoice({ id, billing, billingPeriod })}
                          title="Cancelar Fatura"
                        />
                      </>
                    )}
                  </RowFlex>
                </TableItem>
              </tr>
            ),
          )}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={params.setPage}
      />
      <Modal
        title={`Itens da Fatura ${subscriptionInvoice?.id ? subscriptionInvoice?.id : ""}`}
        confirmLabel="Fechar"
        onCloseModal={() => closeModal(setShowItems)}
        showModal={showItems}
        textAlign="text-left"
        showActions={true}
        hideConfirm={true}
        hideCancel={false}
        cancelLabel="Fechar"
        width="85rem"
      >
        <Flex verticalAlign="center">
          {isPending(requestStatusInvoice) ? (
            <>
              <SpinnerOverlay>
                <ColumnFlex>
                  <Spinner />
                  <Span style={{ marginTop: "0.5rem" }}>Aguarde...</Span>
                </ColumnFlex>
              </SpinnerOverlay>
            </>
          ) : (
            <>
              <CardInfo
                marginLeft={0}
                marginRigth={0}
                label={translate("emitterName")}
                info={
                  subscriptionInvoice?.enterprise?.enterpriseName ||
                  subscriptionInvoice?.enterprise?.user?.name
                }
              />
              <Columns columns="3">
                <CardInfo
                  marginLeft={0}
                  marginRigth={0}
                  label={translate("Due Date")}
                  info={formatDate(subscriptionInvoice.dueDate, 0, false)}
                />
                <CardInfo
                  marginLeft={0}
                  marginRigth={0}
                  label={translate("Value")}
                  info={format(
                    Number(subscriptionInvoice.total).toFixed(2),
                    MASK_TYPES.realWithName,
                  )}
                />
                <CardInfo
                  marginLeft={0}
                  marginRigth={0}
                  label={translate("status")}
                  info={translate(subscriptionInvoice.status)}
                />
              </Columns>
              <Table>
                <TableHeader>
                  <RenderHeaders headers={headersItems} />
                </TableHeader>
                <TableBody>
                  <>
                    {subscriptionInvoice?.invoiceItems?.map(
                      ({ id, description, quantity, price }) => (
                        <tr key={id}>
                          <TableItem padding={colPadding}>{description}</TableItem>
                          <TableItem padding={colPadding} textAlign="right">
                            <Span style={getStyles(price)}>
                              {format(Number(price).toFixed(2), getRealMask(price))}
                            </Span>
                          </TableItem>
                          <TableItem padding={colPadding} textAlign="center">
                            <Span>{quantity}</Span>
                          </TableItem>
                          <TableItem padding={colPadding} textAlign="right">
                            <Span style={getStyles(price)}>
                              {format(
                                Number(Number(price) * quantity).toFixed(2),
                                getRealMask(price),
                              )}
                            </Span>
                          </TableItem>
                        </tr>
                      ),
                    )}
                  </>
                </TableBody>
              </Table>
            </>
          )}
        </Flex>
      </Modal>
      <Modal
        title={`Detalhes da Fatura ${subscriptionInvoice?.id ?? ""}`}
        confirmLabel="Fechar"
        onCloseModal={() => closeModal(setShowDetails)}
        showModal={showDetails}
        textAlign="text-left"
        showActions={true}
        hideConfirm={true}
        hideCancel={false}
        cancelLabel="Fechar"
        width="85rem"
      >
        <Flex verticalAlign="center">
          {isPending(requestStatusInvoice) ? (
            <SpinnerOverlay>
              <ColumnFlex>
                <Spinner />
                <Span style={{ marginTop: "0.5rem" }}>Aguarde...</Span>
              </ColumnFlex>
            </SpinnerOverlay>
          ) : (
            <>
              <CardInfo
                marginLeft={0}
                marginRigth={0}
                label={translate("emitterName")}
                info={
                  subscriptionInvoice?.enterprise?.enterpriseName ??
                  subscriptionInvoice?.enterprise?.user?.name
                }
              />
              <Columns columns="3">
                <CardInfo
                  marginLeft={0}
                  marginRigth={0}
                  label={translate("Due Date")}
                  info={formatDate(subscriptionInvoice.dueDate, 0, false)}
                />
                <CardInfo
                  marginLeft={0}
                  marginRigth={0}
                  label={translate("Value")}
                  info={format(
                    Number(subscriptionInvoice.total).toFixed(2),
                    MASK_TYPES.realWithName,
                  )}
                />
                <CardInfo
                  marginLeft={0}
                  marginRigth={0}
                  label={translate("Status")}
                  info={translate(subscriptionInvoice.status)}
                />
              </Columns>
              {subscriptionInvoice.status === "canceled" &&
                subscriptionInvoice?.newInvoices?.length > 0 && (
                  <Span
                    color="red"
                    style={{
                      marginBottom: "2.4rem",
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "center",
                    }}
                  >
                    Cancelado em favor da fatura de ID {subscriptionInvoice?.newInvoices[0].id}
                    <S.IconView
                      style={{ cursor: "pointer", marginLeft: "1rem" }}
                      onClick={() => handleViewDetails(subscriptionInvoice?.newInvoices[0].id)}
                      title={`Ver detalhes da fatura de ID ${subscriptionInvoice?.newInvoices[0].id}`}
                    />
                  </Span>
                )}
              {subscriptionInvoice.status === "pending" && (
                <>
                  <InputForm
                    type="text"
                    name="invoiceLink"
                    label={translate("Payment Link")}
                    value={parseInvoiceLink(
                      subscriptionInvoice.paymentGatewaySubscriptionInvoiceId,
                    )}
                    onChange={() => {}}
                    readonly
                  />
                  <Columns columns="2" style={{ marginBottom: "2.4rem" }}>
                    <Button
                      handleClick={() =>
                        handleClipboard(
                          parseInvoiceLink(subscriptionInvoice.paymentGatewaySubscriptionInvoiceId),
                        )
                      }
                    >
                      {translate("Copy Link")}
                    </Button>
                    <Button
                      handleClick={() =>
                        handleTab(
                          parseInvoiceLink(subscriptionInvoice.paymentGatewaySubscriptionInvoiceId),
                        )
                      }
                    >
                      {translate("Open in new tab")}
                    </Button>
                  </Columns>
                </>
              )}
              <Columns>
                <InputForm
                  type="text"
                  name="invoiceLinkIugu"
                  label="Link IUGU"
                  value={subscriptionInvoice.invoiceUrl}
                  onChange={() => {}}
                  readonly
                />
              </Columns>
              <Columns columns="2">
                <Button
                  variant="outline"
                  handleClick={() => handleClipboard(subscriptionInvoice.invoiceUrl)}
                >
                  {translate("Copy Link")}
                </Button>
                <Button
                  variant="outline"
                  handleClick={() => handleTab(subscriptionInvoice.invoiceUrl)}
                >
                  {translate("Open in new tab")}
                </Button>
              </Columns>
              {linkCopied && (
                <Flex horizontalAlign="center">
                  <Label style={{ marginTop: "2rem", fontSize: "1.5rem", color: "#0071ee" }}>
                    {translate("Payment Link Copied")}
                  </Label>
                </Flex>
              )}
              <ChargeHistoryContainer>
                <Button
                  variant={"text"}
                  handleClick={toggleShowHistory}
                  title={showHistory ? "Ocultar hist�rico" : "Exibir hist�rico"}
                >
                  Hist�rico de Cobran�as <Chrevon />
                </Button>
                {showHistory && (
                  <Container
                    horizontalAlign="center"
                    horizontalPadding={0}
                    verticalPadding={0}
                    marginBottom={"1REM"}
                  >
                    <Table>
                      <TableHeader>
                        <RenderHeaders headers={headersCharges} />
                      </TableHeader>
                      <TableBody>
                        {subscriptionInvoice.invoiceCharges?.map(
                          ({
                            id,
                            createdAt,
                            user: { name: userName },
                            status,
                            chargeResponse: { lr },
                          }) => (
                            <tr key={`hc${id}`}>
                              <TableItem padding={itemPadding}>{id}</TableItem>
                              <TableItem padding={itemPadding}>
                                {formatDate(createdAt, 0, false, true)}
                              </TableItem>
                              <TableItem padding={itemPadding}>{userName}</TableItem>
                              <TableItem padding={itemPadding}>{lr}</TableItem>
                              <TableItem padding={itemPadding}>{status}</TableItem>
                              <TableItem padding={itemPadding}>
                                <RowFlex>
                                  <S.IconView
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleViewDetailsChargeHistory(id)}
                                    title="Ver detalhes"
                                  />
                                </RowFlex>
                              </TableItem>
                            </tr>
                          ),
                        )}
                      </TableBody>
                    </Table>
                  </Container>
                )}
              </ChargeHistoryContainer>
            </>
          )}
        </Flex>
      </Modal>
      <Modal
        title={`Confirma o cancelamento da fatura ${selectedInvoiceId}?`}
        confirmLabel="Sim"
        onConfirmModal={submitCancelInvoice}
        onCloseModal={closeModal}
        showModal={showConfirmCancelInvoice}
        showActions={true}
        hideConfirm={false}
        hideCancel={false}
        cancelLabel="N�o"
        closeOnConfirm={false}
        width="50rem"
      >
        {CANCEL_INVOICE === REQUEST_PENDING && (
          <SpinnerOverlay>
            <ColumnFlex>
              <Spinner />
              <Span style={{ marginTop: "0.5rem" }}>Aguarde... Cancelando a fatura</Span>
            </ColumnFlex>
          </SpinnerOverlay>
        )}
        {/* {CANCEL_INVOICE !== REQUEST_PENDING && (
          <Flex verticalAlign="center" horizontalAlign="center">
            {selectedInvoiceBilling && (
              <>
                <Checkbox
                  name="billSubscription"
                  label="Permitir refaturamento para o per�odo referente a esta fatura?"
                  value={billSubscription}
                  handleChange={() => setBillSubscription(!billSubscription)}
                  checked={billSubscription}
                />
                <Span>
                  {`Per�odo: `}
                  <strong>{billingPeriod()}</strong>
                </Span>
              </>
            )}
            {!selectedInvoiceBilling && (
              <>
                <Checkbox
                  name="billSubscription"
                  label="Colocar assinatura dispon�vel para faturamento"
                  value={billSubscription}
                  handleChange={() => setBillSubscription(!billSubscription)}
                  checked={billSubscription}
                />
                {billSubscription && (
                  <Span>
                    {translate("A pr�xima cobran�a ser� em ")}
                    <strong>{formatDate(nextBillingDate, 0, false)}</strong>
                  </Span>
                )}
              </>
            )}
          </Flex>
        )} */}
      </Modal>
      {selectedCharge && (
        <Modal
          title={`Detalhes da cobran�a #${selectedCharge?.id}`}
          onCloseModal={closeModalChargeDetails}
          showModal={showChargeDetails}
          hideConfirm={true}
          hideCancel={false}
          cancelLabel="Fechar"
          width="80rem"
          textAlign="text-left"
        >
          <Columns columns="3">
            <CardInfo
              marginLeft={0}
              marginRigth={0}
              label={translate("Charged by")}
              info={selectedCharge?.user?.name}
            />
            <CardInfo
              marginLeft={0}
              marginRigth={0}
              label={translate("Charged at")}
              info={formatDate(selectedCharge?.createdAt, 0, false, true)}
            />
            <CardInfo
              marginLeft={0}
              marginRigth={0}
              label={translate("Installments")}
              info={`${selectedCharge?.chargeResponse?.installments}x`}
            />
          </Columns>
          <Columns columns="3">
            <CardInfo
              marginLeft={0}
              marginRigth={0}
              label={translate("Brand")}
              info={selectedCharge?.selectedCharge?.chargeResponse?.brand}
            />
            <CardInfo
              marginLeft={0}
              marginRigth={0}
              label={translate("Final card digits")}
              info={selectedCharge?.selectedCharge?.chargeResponse?.last4}
            />
            <CardInfo
              marginLeft={0}
              marginRigth={0}
              label={translate("Bank")}
              info={selectedCharge?.selectedCharge?.chargeResponse?.issuer}
            />
          </Columns>
          <Columns>
            <CardInfo
              marginLeft={0}
              marginRigth={0}
              label={"LR"}
              info={`${selectedCharge?.chargeResponse?.lr} - ${selectedCharge?.chargeResponse?.lrAttempt?.description}`}
            />
          </Columns>
          {!selectedCharge?.chargeResponse?.success && (
            <>
              {selectedCharge?.chargeResponse?.lrAttempt?.action && (
                <Columns>
                  <CardInfo
                    marginLeft={0}
                    marginRigth={0}
                    label={translate("Possible actions")}
                    info={selectedCharge?.chargeResponse?.lrAttempt?.action}
                  />
                </Columns>
              )}
              {selectedCharge?.chargeResponse?.lrAttempt?.irreversibleForBrands && (
                <Columns>
                  <CardInfo
                    marginLeft={0}
                    marginRigth={0}
                    label={translate("Irreversible status for brands")}
                    info={selectedCharge?.chargeResponse?.lrAttempt?.irreversibleForBrands}
                  />
                </Columns>
              )}
            </>
          )}
        </Modal>
      )}
      {selectedInvoiceId && (
        <EmailModal
          invoiceId={selectedInvoiceId}
          handleCancel={() => setShowModalMail(false)}
          handleConfirm={submitMail}
          showModalMail={showModalMail}
          setShowModalMail={setShowModalMail}
          emails={emails}
          setEmails={setEmails}
          setMailSubject={setMailSubject}
          mailMessage={mailMessage}
          setMailMessage={setMailMessage}
          invoiceUrl={mailParams?.url}
          copied={linkCopied}
          handleClipboard={handleClipboard}
          EmailTemplate={InvoiceEmailTemplate({
            invoiceUrl: mailParams?.url,
            message: mailMessage,
            contactName: mailParams?.name,
            greetings: mailGreetings,
          })}
          requestStatus={SEND_FINANCIAL_INVOICE_MAIL}
        />
      )}
    </>
  );
};

const ChargeHistoryContainer = styled.div`
  border: none;
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  max-height: 200px;
  overflow: auto;
`;

List.propTypes = {
  items: PropTypes.array,
  params: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
};

List.defaultProps = {
  items: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 5,
};

export default WithEmptyFeedback(List);
