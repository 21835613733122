/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { formatNumberToReal } from "helpers";
import {
  Container,
  Image,
  ContainerMain,
  ContainerTable,
  ContainerData,
  ContainerFooter,
  ContainerHeader,
} from "./styled";
import logo from "../../../assets/svg/logo-colored.svg";

const PageReportBilling = () => {
  const location = useLocation();
  const today = new Date();
  const [data, setData] = useState(location.state.dataReport);
  const [user, setUser] = useState(location.state.userDetails);

  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 200);
  }, []);

  return (
    <Container>
      <ContainerMain>
        <ContainerHeader>
          <ContainerData>
            <p>{data.enterpriseName}</p>
            <p>
              Endereço:&nbsp;&nbsp;{data.street}, {data.number}
            </p>
            <p>Município:&nbsp;&nbsp;{data.city}</p>
            <p>CNPJ:&nbsp;{data.cnpj}</p>
            <p>
              Relatório de Faturamento de: &nbsp; {data.dates.dateBegin} &nbsp;até&nbsp;{" "}
              {data.dates.dateEnd}
            </p>
          </ContainerData>
          <Image src={logo} />
        </ContainerHeader>
        <ContainerTable>
          <table
            id="tabelaReceita"
            style={{ width: "40%", fontSize: "13px", paddingRight: "40px" }}
          >
            <thead>
              <tr>
                <th>Mês / Ano</th>
                <th style={{ textAlign: "right" }}>Receita Bruta</th>
              </tr>
            </thead>
            <tbody>
              {data.reportBilling.map((item) => (
                <tr key={item.sum}>
                  <td>{`${item.month}/${item.year}`}</td>
                  <td style={{ textAlign: "right" }}>{formatNumberToReal(item.sum)}</td>
                </tr>
              ))}
            </tbody>
            <hr />
            <tfoot>
              <tr>
                <td>
                  <strong>Total Geral</strong>
                </td>
                <td className="tdFooter">{formatNumberToReal(data.total)}</td>
              </tr>
              <tr>
                <td>
                  <strong>Média</strong>
                </td>
                <td className="tdFooter">{formatNumberToReal(data.media)}</td>
              </tr>
            </tfoot>
          </table>
          <p>Contagem, &nbsp; &nbsp;{today.toLocaleDateString()}</p>
        </ContainerTable>
      </ContainerMain>
      <ContainerFooter>
        <p>CONTADOR:&nbsp;{user.name.toUpperCase()}</p>
        <p>CRC -&nbsp;{user.crc ? user.crc.toUpperCase() : ""}</p>
      </ContainerFooter>
    </Container>
  );
};

export default PageReportBilling;
