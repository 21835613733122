import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
} from "utils/constants/request";

export const { Types: EmitterTypes, Creators: EmitterActions } = createActions({
  addServicesToEmitter: ["id", "payload"],
  addServicesToEmitterSuccess: ["payload"],
  addProductsToEmitter: ["id", "payload"],
  addProductsToEmitterSuccess: ["payload"],

  addNcmToEmitter: ["id", "payload"],
  addNcmToEmitterSuccess: ["payload"],

  addCfopToEmitter: ["id", "payload"],
  addCfopToEmitterSuccess: ["payload"],

  addCstToEmitter: ["id", "payload"],
  addCstToEmitterSuccess: ["payload"],
  enterpriseLogs: ["params"],
  fetchEmitters: ["params"],
  fetchEmittersSearch: ["params"],
  fetchEmittersChat: ["params"],
  fetchEmittersSuccess: ["payload"],
  logsEmitterSuccess: ["payload"],
  fetchEmittersSearchSuccess: ["payload"],
  fetchEmittersChatSuccess: ["payload"],
  fetchEmitterById: ["id"],
  fetchEmitterClientById: ["id"],
  fetchEmitterByIdSuccess: ["payload"],
  fetchEmitterAuthTypes: ["id"],
  fetchEmitterAuthTypesSuccess: ["payload"],
  fetchEmitterServices: ["id", "queryParams"],
  fetchEmitterServicesSuccess: ["payload"],
  fetchEmitterProducts: ["id", "params"],
  fetchEmitterProductsSuccess: ["payload"],

  getStatusLog: ["id"],
  getStatusLogSuccess: ["payload"],

  fetchEmitterNcm: ["id", "params"],
  fetchEmitterNcmSuccess: ["payload"],

  fetchEmitterCfop: ["id", "params"],
  fetchEmitterCfopSuccess: ["payload"],

  fetchEmitterCst: ["id", "params"],
  fetchEmitterCstSuccess: ["payload"],

  editEmitter: ["id", "payload"],
  editEmitterPlan: ["id", "payload"],
  editEmitterPlanSuccess: ["payload"],
  editEmitterSuccess: ["payload"],
  editEmittersStatus: ["payload"],
  editEmittersStatusSuccess: ["payload"],
  editCertificate: ["id", "payload"],
  editCertificateSuccess: ["payload"],
  editSerialNumber: ["id", "payload"],
  editSerialNumberSuccess: ["payload"],
  exportEmitters: ["enterprises"],
  exportEmittersSuccess: ["payload"],
  getEnterprisesBilling: ["params"],
  getEnterprisesBillingSuccess: ["payload"],
  emittersRequestFailure: ["error", "requestName"],
  logsRequestFailure: ["error", "requestName"],
  fetchEmittersToPinById: ["id"],
  fetchEmittersToPinByIdSuccess: ["payload"],
  cleanState: null,
  resetEditStatus: null,
  cleanRequest: null,
});

const INITIAL_STATE = {
  emitterDetails: {},
  emitterToPinDetails: {},
  emitters: {
    items: [],
  },
  logs: {
    items: [],
  },
  emittersSearch: {
    items: [],
  },
  emittersToChat: {
    items: [],
  },
  emitterAuthTypes: {},
  emitterServices: {},
  emitterProducts: {},

  emitterNcm: {},

  emitterCfop: {},

  emitterCst: {},

  exportEmitters: null,
  enterprisesBilling: {
    items: [],
  },
  statusOfRequests: {
    details: REQUEST_NOT_STARTED,
    detailsToPin: REQUEST_NOT_STARTED,
    editStatus: REQUEST_NOT_STARTED,
    fetch: REQUEST_NOT_STARTED,
    logs: REQUEST_NOT_STARTED,
    getStatusLog: REQUEST_NOT_STARTED,
    getEnterprisesBilling: REQUEST_NOT_STARTED,
    [EmitterTypes.ADD_SERVICES_TO_EMITTER]: REQUEST_NOT_STARTED,
    [EmitterTypes.EDIT_EMITTER]: REQUEST_NOT_STARTED,
    [EmitterTypes.EDIT_SERIAL_NUMBER]: REQUEST_NOT_STARTED,
    [EmitterTypes.EXPORT_EMITTERS]: REQUEST_NOT_STARTED,
    [EmitterTypes.FETCH_EMITTERS_CHAT]: REQUEST_NOT_STARTED,
    [EmitterTypes.FETCH_EMITTER_AUTH_TYPES]: REQUEST_PENDING,
    [EmitterTypes.FETCH_EMITTER_SERVICES]: REQUEST_PENDING,
  },
};

const addServicesToEmitter = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.ADD_SERVICES_TO_EMITTER]: REQUEST_PENDING,
  },
});

const addServicesToEmitterSuccess = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.ADD_SERVICES_TO_EMITTER]: REQUEST_RESOLVED,
  },
});

const addProductsToEmitter = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.ADD_PRODUCTS_TO_EMITTER]: REQUEST_PENDING,
  },
});

const addProductsToEmitterSuccess = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.ADD_PRODUCTS_TO_EMITTER]: REQUEST_RESOLVED,
  },
});

const addNcmToEmitter = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.ADD_NCM_TO_EMITTER]: REQUEST_PENDING,
  },
});

const addNcmToEmitterSuccess = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.ADD_NCM_TO_EMITTER]: REQUEST_RESOLVED,
  },
});

const addCfopToEmitter = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.ADD_CFOP_TO_EMITTER]: REQUEST_PENDING,
  },
});

const addCfopToEmitterSuccess = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.ADD_CFOP_TO_EMITTER]: REQUEST_RESOLVED,
  },
});

const addCstToEmitter = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.ADD_CST_TO_EMITTER]: REQUEST_PENDING,
  },
});

const getStatusLog = (state) => ({
  ...state,
  EnterpriseRequests: {
    ...state,
    getStatusLog: REQUEST_PENDING,
  },
});

const getStatusLogSuccess = (state, { payload }) => ({
  ...state,
  statusLog: payload,
  EnterpriseRequests: {
    ...state.EnterpriseRequests,
    getStatusLog: REQUEST_RESOLVED,
  },
});

const addCstToEmitterSuccess = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.ADD_CST_TO_EMITTER]: REQUEST_RESOLVED,
  },
});

const fetchEmitters = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    fetch: REQUEST_PENDING,
  },
});

const enterpriseLogs = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    logs: REQUEST_PENDING,
  },
});

const fetchEmittersSearch = (state) => ({
  ...state,
  fetchingStatus: REQUEST_PENDING,
});

const fetchEmittersChat = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.FETCH_EMITTERS_CHAT]: REQUEST_PENDING,
  },
});

const fetchEmittersSuccess = (state, { payload }) => ({
  ...state,
  emitters: payload,
  statusOfRequests: {
    ...state.statusOfRequests,
    fetch: REQUEST_RESOLVED,
  },
});

const logsEmitterSuccess = (state, { payload }) => ({
  ...state,
  logs: payload,
  statusOfRequests: {
    ...state.statusOfRequests,
    logs: REQUEST_RESOLVED,
  },
});

const fetchEmittersSearchSuccess = (state, { payload }) => ({
  ...state,
  emittersSearch: payload,
  fetchingStatus: REQUEST_RESOLVED,
});

const fetchEmittersChatSuccess = (state, { payload }) => ({
  ...state,
  emittersToChat: payload,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.FETCH_EMITTERS_CHAT]: REQUEST_RESOLVED,
  },
});

const fetchEmitterById = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    details: REQUEST_PENDING,
  },
});

const fetchEmitterClientById = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    details: REQUEST_PENDING,
  },
});

const fetchEmitterByIdSuccess = (state, { payload }) => ({
  ...state,
  emitterDetails: payload,
  fetchingStatus: REQUEST_RESOLVED,
  statusOfRequests: {
    ...state.statusOfRequests,
    details: REQUEST_RESOLVED,
  },
});

const fetchEmitterAuthTypes = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.FETCH_EMITTER_AUTH_TYPES]: REQUEST_PENDING,
  },
});

const fetchEmitterAuthTypesSuccess = (state, { payload = {} }) => ({
  ...state,
  emitterAuthTypes: payload,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.FETCH_EMITTER_AUTH_TYPES]: REQUEST_RESOLVED,
  },
});

const fetchEmitterServices = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.FETCH_EMITTER_SERVICES]: REQUEST_PENDING,
  },
});

const fetchEmitterServicesSuccess = (state, { payload = {} }) => ({
  ...state,
  emitterServices: payload,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.FETCH_EMITTER_SERVICES]: REQUEST_RESOLVED,
  },
});

const fetchEmitterProducts = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.FETCH_EMITTER_PRODUCTS]: REQUEST_PENDING,
  },
});

const fetchEmitterProductsSuccess = (state, { payload = {} }) => ({
  ...state,
  emitterProducts: payload,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.FETCH_EMITTER_PRODUCTS]: REQUEST_RESOLVED,
  },
});

const fetchEmitterNcm = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.FETCH_EMITTER_NCM]: REQUEST_PENDING,
  },
});

const fetchEmitterNcmSuccess = (state, { payload = {} }) => ({
  ...state,
  emitterNcm: payload,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.FETCH_EMITTER_NCM]: REQUEST_RESOLVED,
  },
});

const fetchEmitterCfop = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.FETCH_EMITTER_CFOP]: REQUEST_PENDING,
  },
});

const fetchEmitterCfopSuccess = (state, { payload = {} }) => ({
  ...state,
  emitterCfop: payload,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.FETCH_EMITTER_CFOP]: REQUEST_RESOLVED,
  },
});

const fetchEmitterCst = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.FETCH_EMITTER_CST]: REQUEST_PENDING,
  },
});

const fetchEmitterCstSuccess = (state, { payload = {} }) => ({
  ...state,
  emitterCst: payload,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.FETCH_EMITTER_CST]: REQUEST_RESOLVED,
  },
});

const editEmitter = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.EDIT_EMITTER]: REQUEST_PENDING,
  },
});

const editEmitterSuccess = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.EDIT_EMITTER]: REQUEST_RESOLVED,
  },
});

const editEmitterPlan = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    editEmitterPlan: REQUEST_PENDING,
  },
});

const editEmitterPlanSuccess = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    editEmitterPlan: REQUEST_RESOLVED,
  },
});

const editEmittersStatus = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    editStatus: REQUEST_PENDING,
  },
});

const editEmittersStatusSuccess = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    editStatus: REQUEST_RESOLVED,
  },
});

const editCertificate = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.EDIT_CERTIFICATE]: REQUEST_PENDING,
  },
});

const editCertificateSuccess = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.EDIT_CERTIFICATE]: REQUEST_RESOLVED,
  },
});

const editSerialNumber = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.EDIT_SERIAL_NUMBER]: REQUEST_PENDING,
  },
});

const editSerialNumberSuccess = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.EDIT_SERIAL_NUMBER]: REQUEST_RESOLVED,
  },
});

const exportEmitters = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.EXPORT_EMITTERS]: REQUEST_PENDING,
  },
});

const exportEmittersSuccess = (state, { payload }) => ({
  ...state,
  exportEmitters: payload,
  statusOfRequests: {
    ...state.statusOfRequests,
    [EmitterTypes.EXPORT_EMITTERS]: REQUEST_RESOLVED,
  },
});

const emittersRequestFailure = (state, { error, requestName }) => ({
  ...state,
  error,
  fetchingStatus: REQUEST_REJECTED,
  statusOfRequests: {
    ...state.statusOfRequests,
    [requestName]: REQUEST_REJECTED,
  },
});

const logsRequestFailure = (state, { error, requestName }) => ({
  ...state,
  error,
  fetchingStatus: REQUEST_REJECTED,
  statusOfRequests: {
    ...state.statusOfRequests,
    [requestName]: REQUEST_REJECTED,
  },
});

const getEnterprisesBilling = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    getEnterprisesBilling: REQUEST_PENDING,
  },
});

const getEnterprisesBillingSuccess = (state, { payload }) => ({
  ...state,
  enterprisesBilling: payload,
  statusOfRequests: {
    ...state.statusOfRequests,
    getEnterprisesBilling: REQUEST_RESOLVED,
  },
});

const fetchEmittersToPinById = (state) => ({
  ...state,
  fetchingStatus: REQUEST_PENDING,
  statusOfRequests: {
    ...state.statusOfRequests,
    detailsToPin: REQUEST_PENDING,
  },
});

const fetchEmittersToPinByIdSuccess = (state, { payload }) => ({
  ...state,
  emitterToPinDetails: payload,
  fetchingStatus: REQUEST_RESOLVED,
  statusOfRequests: {
    ...state.statusOfRequests,
    detailsToPin: REQUEST_RESOLVED,
  },
});

const cleanState = () => ({ ...INITIAL_STATE });
const cleanRequest = (state) => ({
  ...state,
  statusOfRequests: {
    details: REQUEST_NOT_STARTED,
    detailsToPin: REQUEST_NOT_STARTED,
    editStatus: REQUEST_NOT_STARTED,
    fetch: REQUEST_NOT_STARTED,
    logs: REQUEST_NOT_STARTED,
    getEnterprisesBilling: REQUEST_NOT_STARTED,
    [EmitterTypes.ADD_SERVICES_TO_EMITTER]: REQUEST_NOT_STARTED,
    [EmitterTypes.EDIT_EMITTER]: REQUEST_NOT_STARTED,
    [EmitterTypes.EDIT_SERIAL_NUMBER]: REQUEST_NOT_STARTED,
    [EmitterTypes.EXPORT_EMITTERS]: REQUEST_NOT_STARTED,
    [EmitterTypes.FETCH_EMITTERS_CHAT]: REQUEST_NOT_STARTED,
    [EmitterTypes.FETCH_EMITTER_AUTH_TYPES]: REQUEST_PENDING,
    [EmitterTypes.FETCH_EMITTER_SERVICES]: REQUEST_PENDING,
    [EmitterTypes.ADD_NCM_TO_EMITTER]: REQUEST_PENDING,
    [EmitterTypes.ADD_CFOP_TO_EMITTER]: REQUEST_PENDING,
    [EmitterTypes.ADD_CST_TO_EMITTER]: REQUEST_PENDING,
    [EmitterTypes.ADD_PRODUCTS_TO_EMITTER]: REQUEST_PENDING,
  },
});

const resetEditStatus = (state) => ({
  ...state,
  statusOfRequests: {
    ...state.statusOfRequests,
    editStatus: REQUEST_NOT_STARTED,
  },
});

export default createReducer(INITIAL_STATE, {
  [EmitterTypes.ADD_SERVICES_TO_EMITTER]: addServicesToEmitter,
  [EmitterTypes.ADD_SERVICES_TO_EMITTER_SUCCESS]: addServicesToEmitterSuccess,
  [EmitterTypes.ADD_PRODUCTS_TO_EMITTER]: addProductsToEmitter,
  [EmitterTypes.ADD_PRODUCTS_TO_EMITTER_SUCCESS]: addProductsToEmitterSuccess,

  [EmitterTypes.ADD_NCM_TO_EMITTER]: addNcmToEmitter,
  [EmitterTypes.ADD_NCM_TO_EMITTER_SUCCESS]: addNcmToEmitterSuccess,

  [EmitterTypes.ADD_CFOP_TO_EMITTER]: addCfopToEmitter,
  [EmitterTypes.ADD_CFOP_TO_EMITTER_SUCCESS]: addCfopToEmitterSuccess,

  [EmitterTypes.ADD_CST_TO_EMITTER]: addCstToEmitter,
  [EmitterTypes.ADD_CST_TO_EMITTER_SUCCESS]: addCstToEmitterSuccess,

  [EmitterTypes.FETCH_EMITTERS]: fetchEmitters,
  [EmitterTypes.FETCH_EMITTERS_SEARCH]: fetchEmittersSearch,
  [EmitterTypes.FETCH_EMITTERS_CHAT]: fetchEmittersChat,
  [EmitterTypes.FETCH_EMITTERS_SUCCESS]: fetchEmittersSuccess,
  [EmitterTypes.FETCH_EMITTERS_SEARCH_SUCCESS]: fetchEmittersSearchSuccess,
  [EmitterTypes.FETCH_EMITTERS_CHAT_SUCCESS]: fetchEmittersChatSuccess,
  [EmitterTypes.FETCH_EMITTER_BY_ID]: fetchEmitterById,
  [EmitterTypes.FETCH_EMITTER_CLIENT_BY_ID]: fetchEmitterClientById,
  [EmitterTypes.FETCH_EMITTER_BY_ID_SUCCESS]: fetchEmitterByIdSuccess,
  [EmitterTypes.FETCH_EMITTER_AUTH_TYPES]: fetchEmitterAuthTypes,
  [EmitterTypes.FETCH_EMITTER_AUTH_TYPES_SUCCESS]: fetchEmitterAuthTypesSuccess,
  [EmitterTypes.FETCH_EMITTER_SERVICES]: fetchEmitterServices,
  [EmitterTypes.FETCH_EMITTER_SERVICES_SUCCESS]: fetchEmitterServicesSuccess,
  [EmitterTypes.FETCH_EMITTER_PRODUCTS]: fetchEmitterProducts,
  [EmitterTypes.FETCH_EMITTER_PRODUCTS_SUCCESS]: fetchEmitterProductsSuccess,

  [EmitterTypes.FETCH_EMITTER_NCM]: fetchEmitterNcm,
  [EmitterTypes.FETCH_EMITTER_NCM_SUCCESS]: fetchEmitterNcmSuccess,

  [EmitterTypes.FETCH_EMITTER_CFOP]: fetchEmitterCfop,
  [EmitterTypes.FETCH_EMITTER_CFOP_SUCCESS]: fetchEmitterCfopSuccess,

  [EmitterTypes.FETCH_EMITTER_CST]: fetchEmitterCst,
  [EmitterTypes.FETCH_EMITTER_CST_SUCCESS]: fetchEmitterCstSuccess,

  [EmitterTypes.EDIT_EMITTER]: editEmitter,
  [EmitterTypes.EDIT_EMITTER_SUCCESS]: editEmitterSuccess,
  [EmitterTypes.EDIT_EMITTERS_STATUS]: editEmittersStatus,
  [EmitterTypes.EDIT_EMITTERS_STATUS_SUCCESS]: editEmittersStatusSuccess,
  [EmitterTypes.EDIT_EMITTER_PLAN]: editEmitterPlan,
  [EmitterTypes.EDIT_EMITTER_PLAN_SUCCESS]: editEmitterPlanSuccess,
  [EmitterTypes.EDIT_CERTIFICATE]: editCertificate,
  [EmitterTypes.EDIT_CERTIFICATE_SUCCESS]: editCertificateSuccess,
  [EmitterTypes.EDIT_SERIAL_NUMBER]: editSerialNumber,
  [EmitterTypes.EDIT_SERIAL_NUMBER_SUCCESS]: editSerialNumberSuccess,
  [EmitterTypes.EXPORT_EMITTERS]: exportEmitters,
  [EmitterTypes.EXPORT_EMITTERS_SUCCESS]: exportEmittersSuccess,
  [EmitterTypes.EMITTERS_REQUEST_FAILURE]: emittersRequestFailure,
  [EmitterTypes.GET_ENTERPRISES_BILLING]: getEnterprisesBilling,
  [EmitterTypes.GET_ENTERPRISES_BILLING_SUCCESS]: getEnterprisesBillingSuccess,
  [EmitterTypes.CLEAN_STATE]: cleanState,
  [EmitterTypes.RESET_EDIT_STATUS]: resetEditStatus,
  [EmitterTypes.ENTERPRISE_LOGS]: enterpriseLogs,
  [EmitterTypes.LOGS_REQUEST_FAILURE]: logsRequestFailure,
  [EmitterTypes.LOGS_EMITTER_SUCCESS]: logsEmitterSuccess,
  [EmitterTypes.FETCH_EMITTERS_TO_PIN_BY_ID]: fetchEmittersToPinById,
  [EmitterTypes.FETCH_EMITTERS_TO_PIN_BY_ID_SUCCESS]: fetchEmittersToPinByIdSuccess,
  [EmitterTypes.CLEAN_REQUEST]: cleanRequest,
  [EmitterTypes.GET_STATUS_LOG]: getStatusLog,
  [EmitterTypes.GET_STATUS_LOG_SUCCESS]: getStatusLogSuccess,
});
