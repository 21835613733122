import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "components/core/Button";
import Checkbox from "components/core/Checkbox";
import InputForm from "components/core/InputForm";
import { Flex } from "components/core/Grid";
import { Form } from "components/core/Form";
import { urls } from "utils/constants";
import { validationSchema, defaultValues } from "utils/schemas/auth";
import * as S from "./styled";

const SignIn = ({ handleSignIn, errorKey }) => {
  const { t: translate } = useTranslation();
  const { register, handleSubmit, errors, setError } = useForm({
    validationSchema,
    defaultValues,
    mode: "onBlur",
    submitFocusError: true,
    nativeValidation: false,
  });

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (errorKey) {
      setError("password", {
        type: "manual",
        message: translate(errorKey),
      });
    }
  }, [errorKey, setError, translate]);

  return (
    <Flex verticalAlign="center">
      <Form onSubmit={handleSubmit(handleSignIn)} data-testid="form-sign-in">
        <Flex horizontalAlign="center">
          <InputForm
            register={register}
            type="email"
            name="email"
            label={translate("email")}
            placeholder={translate("email")}
            autoComplete="username"
            errorMsg={errors?.email?.message}
            data-cy="email"
          />
          <InputForm
            register={register}
            type={values.showPassword ? "text" : "password"}
            name="password"
            label={translate("password")}
            placeholder={translate("password")}
            autoComplete="current-password"
            errorMsg={errors?.password?.message ?? errors?.password?.types?.message}
            data-cy="password"
          >
            {values.showPassword ? (
              <S.IconPasswordShow onClick={handleClickShowPassword} />
            ) : (
              <S.IconPasswordHide onClick={handleClickShowPassword} />
            )}
          </InputForm>

          {/*
          <Flex direction="row" verticalAlign="space-between">
            <Checkbox onClick={handleClickShowPassword} name="showPassword" label={translate("showPassword")} />
          </Flex>
            */}

          <Flex direction="row" verticalAlign="space-between">
            <Checkbox register={register} name="remember" label={translate("rememberMe")} />
            <S.BtnLink to={urls.ROUTES.recoverPassword.path}>
              {translate("forgotPassword")}
            </S.BtnLink>
          </Flex>
          <Button data-cy="submit" type="submit">
            Entrar
          </Button>
        </Flex>
      </Form>
    </Flex>
  );
};

SignIn.propTypes = {
  handleSignIn: PropTypes.func.isRequired,
  errorKey: PropTypes.string,
};

SignIn.defaultProps = {
  errorKey: PropTypes.string,
};

export default SignIn;
