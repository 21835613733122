import { takeLatest, put } from "redux-saga/effects";
import { Types, Actions } from "../ducks/financialSubscriptions";
import { DELETE, GET, POST, PUT } from "utils/constants";

import api from "services/api";

function* billSubscription({ params, type }) {
  try {
    const { id, paymentDate: newPaymentDate, billingPeriod, subscriptionPeriods } = params;
    const { data } = yield api({
      method: POST,
      url: `/admin/financial/subscriptions/${id}/bill`,
      data: { newPaymentDate, billingPeriod, subscriptionPeriods },
    });
    yield put(Actions.billSubscriptionSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* cancelInvoice({ params, type }) {
  try {
    const { data } = yield api({
      method: DELETE,
      url: `/admin/financial/subscriptions/${params.subscriptionId}/invoices/${params.invoiceId}/${
        params.billSubscription ? 1 : 0
      }`,
    });
    yield put(Actions.cancelInvoiceSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* chargeFinancialSubscriptionInvoice({ params, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: `/admin/financial/subscriptions/${params.subscriptionId}/invoices/${params.invoiceId}/charge/`,
      data: params,
    });
    yield put(Actions.chargeFinancialSubscriptionInvoiceSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* createFinancialSubscription({ params, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: `/admin/financial/subscriptions/`,
      data: params,
    });
    yield put(Actions.createFinancialSubscriptionSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* deleteFinancialSubscriptionItem({ params, type }) {
  try {
    const { data } = yield api({
      method: DELETE,
      url: `/admin/financial/subscriptions/${params.subscriptionId}/items/${params.itemId}`,
    });
    yield put(Actions.deleteFinancialSubscriptionItemSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* deleteFinancialSubscriptionItems({ params, type }) {
  try {
    const { data } = yield api({
      method: DELETE,
      url: `/admin/financial/subscriptions/${params.subscriptionId}/items/`,
      params: { ids: params.ids },
    });
    yield put(Actions.deleteFinancialSubscriptionItemsSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* exportSubscriptions({ params, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: `/admin/financial/subscriptions/export/`,
      data: params,
    });
    yield put(Actions.exportSubscriptionsSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* getCouponForSubscription({ couponCode, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: `/admin/financial/subscriptions/coupon/${couponCode}`,
    });
    yield put(Actions.getCouponForSubscriptionSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* getFinancialEnterpriseSubscriptions({ enterpriseId, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: `/admin/financial/enterprises/${enterpriseId}/subscriptions`,
    });
    yield put(Actions.getFinancialEnterpriseSubscriptionsSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* getFinancialSubscription({ id, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: `/admin/financial/subscriptions/${id}`,
    });
    yield put(Actions.getFinancialSubscriptionSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* getFinancialSubscriptionInvoicesToCharge({ id, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: `/admin/financial/subscriptions/${id}/available-invoices-to-charge/`,
    });
    yield put(Actions.getFinancialSubscriptionInvoicesToChargeSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* getFinancialSubscriptionInvoice({ params, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: `/admin/financial/subscriptions/${params.subscriptionId}/invoices/${params.invoiceId}/`,
    });
    yield put(Actions.getFinancialSubscriptionInvoiceSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* getFinancialSubscriptionInvoiceItems({ params, type }) {
  try {
    const { invoiceId, subscriptionId, ...rest } = params;
    const { data } = yield api({
      method: GET,
      url: `/admin/financial/subscriptions/${subscriptionId}/invoices/${invoiceId}/items/`,
      params: rest,
    });
    yield put(Actions.getFinancialSubscriptionInvoiceItemsSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* getFinancialSubscriptionInvoices({ params, type }) {
  try {
    const id = params.id;
    delete params.id;
    const { data } = yield api({
      method: GET,
      url: `/admin/financial/subscriptions/${id}/invoices`,
      params,
    });
    yield put(Actions.getFinancialSubscriptionInvoicesSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* getFinancialSubscriptionItemsByCycles({ params, type }) {
  try {
    const { id, cycles } = params;
    const { data } = yield api({
      method: GET,
      url: `/admin/financial/subscriptions/${id}/items-by-cycles/`,
      params: { cycles },
    });
    yield put(Actions.getFinancialSubscriptionItemsByCyclesSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* getFinancialSubscriptionAvailableBillingPeriods({ id, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: `/admin/financial/subscriptions/${id}/available-billing-periods/`,
    });
    yield put(Actions.getFinancialSubscriptionAvailableBillingPeriodsSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* getFinancialSubscriptionBillingsFrom({ id, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: `/admin/financial/subscriptions/${id}/billings-from/`,
    });
    yield put(Actions.getFinancialSubscriptionBillingsFromSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* getFinancialSubscriptionItems({ params, type }) {
  try {
    const id = params.id;
    delete params.id;
    const { data } = yield api({
      method: GET,
      url: `/admin/financial/subscriptions/${id}/items`,
      params,
    });
    yield put(Actions.getFinancialSubscriptionItemsSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* getFinancialSubscriptionPaymentForms({ params, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: `/admin/financial/subscriptions/${params.subscriptionId}/payment-forms/`,
      data: params,
    });
    yield put(Actions.getFinancialSubscriptionPaymentFormsSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* getFinancialSubscriptions({ params, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: `/admin/financial/subscriptions/`,
      params,
    });
    yield put(Actions.getFinancialSubscriptionsSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* getNewFinancialSubscriptionParams({ emitterIdOrCNPJ, type }) {
  try {
    const { data } = yield api({
      method: GET,
      url: `/admin/financial/subscriptions/${emitterIdOrCNPJ}/new-subscription-params/`,
    });
    yield put(Actions.getNewFinancialSubscriptionParamsSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* saveFinancialSubscription({ params, type }) {
  try {
    const { data } = yield api({
      method: PUT,
      url: `/admin/financial/subscriptions/${params.id}/`,
      data: params,
    });
    yield put(Actions.saveFinancialSubscriptionSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* saveFinancialSubscriptionItem({ params, type }) {
  try {
    const subscriptionId = params.subscriptionId;
    const itemId = params.id;
    delete params.id;
    delete params.subscriptionId;
    const { data } = yield api({
      method: itemId ? PUT : POST,
      url: `/admin/financial/subscriptions/${subscriptionId}/items/${itemId || ""}`,
      data: params,
    });
    yield put(Actions.saveFinancialSubscriptionItemSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* sendFinancialInvoiceMail({ params, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: `/admin/financial/invoices/send-mail`,
      data: params,
    });
    yield put(Actions.sendFinancialInvoiceMailSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* sendInvoiceToProtheus({ params, type }) {
  try {
    const { data } = yield api({
      method: POST,
      url: `/admin/financial/invoices/send-protheus`,
      data: params,
    });
    yield put(Actions.sendInvoiceToProtheusSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

function* simulateFinancialSubscription({ params, type }) {
  try {
    const { id, billingPeriod } = params;
    const { data } = yield api({
      method: GET,
      url: `/admin/financial/subscriptions/${id}/simulate`,
      params: { billingPeriod },
    });
    yield put(Actions.simulateFinancialSubscriptionSuccess(data));
  } catch (error) {
    yield put(Actions.requestFailure(error?.response?.data || error, type));
  }
}

export function* watchSagas() {
  yield takeLatest(Types.BILL_SUBSCRIPTION, billSubscription);
  yield takeLatest(Types.CANCEL_INVOICE, cancelInvoice);
  yield takeLatest(Types.CHARGE_FINANCIAL_SUBSCRIPTION_INVOICE, chargeFinancialSubscriptionInvoice);
  yield takeLatest(Types.CREATE_FINANCIAL_SUBSCRIPTION, createFinancialSubscription);
  yield takeLatest(Types.DELETE_FINANCIAL_SUBSCRIPTION_ITEM, deleteFinancialSubscriptionItem);
  yield takeLatest(Types.DELETE_FINANCIAL_SUBSCRIPTION_ITEMS, deleteFinancialSubscriptionItems);
  yield takeLatest(Types.EXPORT_SUBSCRIPTIONS, exportSubscriptions);
  yield takeLatest(Types.GET_COUPON_FOR_SUBSCRIPTION, getCouponForSubscription);
  yield takeLatest(
    Types.GET_FINANCIAL_ENTERPRISE_SUBSCRIPTIONS,
    getFinancialEnterpriseSubscriptions,
  );
  yield takeLatest(Types.GET_FINANCIAL_SUBSCRIPTION, getFinancialSubscription);
  yield takeLatest(Types.GET_FINANCIAL_SUBSCRIPTION_INVOICE, getFinancialSubscriptionInvoice);
  yield takeLatest(
    Types.GET_FINANCIAL_SUBSCRIPTION_INVOICE_ITEMS,
    getFinancialSubscriptionInvoiceItems,
  );
  yield takeLatest(Types.GET_FINANCIAL_SUBSCRIPTION_INVOICES, getFinancialSubscriptionInvoices);
  yield takeLatest(
    Types.GET_FINANCIAL_SUBSCRIPTION_INVOICES_TO_CHARGE,
    getFinancialSubscriptionInvoicesToCharge,
  );
  yield takeLatest(Types.GET_FINANCIAL_SUBSCRIPTION_ITEMS, getFinancialSubscriptionItems);
  yield takeLatest(
    Types.GET_FINANCIAL_SUBSCRIPTION_ITEMS_BY_CYCLES,
    getFinancialSubscriptionItemsByCycles,
  );
  yield takeLatest(
    Types.GET_FINANCIAL_SUBSCRIPTION_PAYMENT_FORMS,
    getFinancialSubscriptionPaymentForms,
  );
  yield takeLatest(
    Types.GET_FINANCIAL_SUBSCRIPTION_AVAILABLE_BILLING_PERIODS,
    getFinancialSubscriptionAvailableBillingPeriods,
  );
  yield takeLatest(
    Types.GET_FINANCIAL_SUBSCRIPTION_BILLINGS_FROM,
    getFinancialSubscriptionBillingsFrom,
  );
  yield takeLatest(Types.GET_FINANCIAL_SUBSCRIPTIONS, getFinancialSubscriptions);
  yield takeLatest(Types.GET_NEW_FINANCIAL_SUBSCRIPTION_PARAMS, getNewFinancialSubscriptionParams);
  yield takeLatest(Types.SAVE_FINANCIAL_SUBSCRIPTION, saveFinancialSubscription);
  yield takeLatest(Types.SAVE_FINANCIAL_SUBSCRIPTION_ITEM, saveFinancialSubscriptionItem);
  yield takeLatest(Types.SEND_FINANCIAL_INVOICE_MAIL, sendFinancialInvoiceMail);
  yield takeLatest(Types.SEND_INVOICE_TO_PROTHEUS, sendInvoiceToProtheus);
  yield takeLatest(Types.SIMULATE_FINANCIAL_SUBSCRIPTION, simulateFinancialSubscription);
}
