import { memo, useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import io from "socket.io-client";
import icon from "assets/favicon.png";
import { isNotInit } from "components/core/WithSpinner";
import useClaims from "hooks/useClaims";
import { ChatActions } from "store/ducks/chat";
import { NotificationActions } from "store/ducks/notification";
import { base, NOTIFICATIONS_SOCKET, idRoles, ROLE_ACCOUNTANT, ROUTES } from "utils/constants";
import { ROLE_MASTER } from "utils/constants/roles";

const NotificationsService = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { NotificationRequests, unreadNotifications } = useSelector(
    ({ notification }) => notification,
  );
  const { totalChatsUnread } = useSelector(({ chat }) => chat);
  const { token: authToken } = useClaims();
  const ioClientRef = useRef();
  const [socketToken, setSocketToken] = useState();

  const showNotification = (body) => {
    const notification = new Notification("Notificações Obvia", {
      body,
      icon,
    });

    notification.onclick = (e) => {
      e.preventDefault();
      window.focus();
      notification.close();
    };
  };

  useEffect(() => {
    ioClientRef.current = io.connect(base, {
      path: NOTIFICATIONS_SOCKET,
      query: { token: authToken },
    });
  }, [authToken]);

  useEffect(() => {
    if ([ROLE_MASTER] === 1) {
      dispatch(
        NotificationActions.fetchNotifications(
          {
            page: 1,
            perPage: 10,
          },
          true,
        ),
      );
    }
  }, [dispatch]);

  const handleNewNotification = useCallback(
    (notification) => {
      showNotification(`Nova atualização de ${notification?.payload?.enterpriseName}`);
      dispatch(NotificationActions.addNotification(notification));
    },
    [dispatch],
  );

  const dispatchFetchTypes = useCallback(() => {
    if (isNotInit(NotificationRequests.FETCH_TYPES)) {
      dispatch(NotificationActions.fetchTypes({ idRole: idRoles[ROLE_ACCOUNTANT] }));
    }
  }, [dispatch, NotificationRequests.FETCH_TYPES]);

  useEffect(() => {
    dispatchFetchTypes();
  }, [dispatchFetchTypes]);

  useEffect(() => {
    ioClientRef.current.on("notification", handleNewNotification);
    ioClientRef.current.once("allowed", setSocketToken);
  }, [handleNewNotification]);

  useEffect(() => {
    if (socketToken) {
      ioClientRef.current.emit("room", socketToken);
      ioClientRef.current.emit("unread-chats", socketToken);
    }
  }, [socketToken]);

  const handleTotalUnreadChats = useCallback(
    ({ total = 0 }) => {
      if (total !== totalChatsUnread) {
        showNotification("Nova mensagem no atendimento");
        dispatch(ChatActions.fetchTotalChatsUnreadSuccess(total));
      }

      if (pathname === ROUTES.customerService.path) {
        dispatch(ChatActions.fetchChats({ perPage: 15 }, true));
      }
    },
    [dispatch, totalChatsUnread, pathname],
  );

  useEffect(() => {
    ioClientRef.current.on("total-unread-chats", handleTotalUnreadChats);
  }, [handleTotalUnreadChats]);

  const handleTotalUnreadNotifications = useCallback(
    (total = 0) => {
      if (total !== unreadNotifications) {
        dispatch(NotificationActions.fetchTotalNotificationsUnreadSuccess(total));
      }
    },
    [dispatch, unreadNotifications],
  );

  useEffect(() => {
    ioClientRef.current.on("total-unread-notifications", handleTotalUnreadNotifications);
  }, [handleTotalUnreadNotifications]);

  return null;
};

export default memo(NotificationsService);
