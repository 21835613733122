import React, { useEffect, useState, lazy, useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChatActions } from "store/ducks/chat";
import { LayoutActions } from "store/ducks/layout";
import WithSpinner from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import ChatRoom from "components/presentation/CustomerService/ChatRoom";
import { useChat, useInfiniteScrollTop, useRequest } from "hooks";

const DeleteMessage = lazy(() => import("containers/CustomerService/ChatRoom/DeleteMessage"));
const ChatRoomWithSpinner = WithSpinner(ChatRoom);
const DeleteMessageWithSuspense = WithSuspense(DeleteMessage);

const ChatRoomContainer = () => {
  const dispatch = useDispatch();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [idMessageToDelete, setIdMessageToDelete] = useState(false);
  const [documentName, setDocumentName] = useState();
  const { chats, chatRoom, messageOnLoad, ChatRequests } = useSelector(({ chat }) => chat);
  const { enterpriseName, name, cnpj, idChat, idUser, idEnterprise } = chatRoom;

  const updateChats = (id, newMessage, createdAt = new Date().toJSON()) => {
    const updatedChats = chats.map(({ idUser: chatIdUser, message, ...chat }) => ({
      ...chat,
      idUser: chatIdUser,
      message: chatIdUser === id ? newMessage : message,
      createdAt,
    }));
    dispatch(ChatActions.fetchChatsSuccess(updatedChats));
  };

  const {
    messages,
    loadingMessages,
    requestLoadingMore,
    sendMessage,
    removeMessageById,
    setPage,
    hasMore,
  } = useChat(idUser, messageOnLoad, ({ message, createdAt }) =>
    updateChats(idUser, message, createdAt),
  );
  const lastMessageRef = useInfiniteScrollTop(requestLoadingMore, hasMore, setPage);
  const firstMessageRef = useCallback((node) => {
    if (node) {
      node.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    if (idChat) {
      dispatch(ChatActions.readChatById(idChat));
    }
  }, [dispatch, idChat]);

  const handleDeleteMessage = (idMessage) => {
    setIdMessageToDelete(idMessage);
    setShowDeleteConfirm(true);
  };

  const deleteMessage = () => {
    dispatch(ChatActions.deleteMessageById(idMessageToDelete));
    removeMessageById(idMessageToDelete);
    updateChats(idUser, messages[1]?.message ?? "");
    setShowDeleteConfirm(false);
  };

  const handleSendAttachment = (document) => {
    const formData = new FormData();
    const params = { userId: idUser };
    formData.append("document", document);
    dispatch(ChatActions.sendAttachment(formData, params));
    setDocumentName(document.name);
  };

  const handlePinEmitter = () => {
    dispatch(
      LayoutActions.pinEmitter({
        id: idEnterprise,
        enterpriseName,
        cnpj,
        user: {
          id: idUser,
          name,
        },
      }),
    );
  };

  useRequest({
    request: ChatRequests.SEND_ATTACHMENT,
    onResolved: () => {
      updateChats(idUser, documentName);
      setDocumentName("");
    },
  });

  return (
    <>
      <ChatRoomWithSpinner
        isLoading={loadingMessages}
        messages={messages}
        sendMessage={sendMessage}
        handleDeleteMessage={handleDeleteMessage}
        handleSendAttachment={handleSendAttachment}
        handlePinEmitter={handlePinEmitter}
        lastMessageRef={lastMessageRef}
        firstMessageRef={firstMessageRef}
        emitterName={enterpriseName ?? name}
        cnpj={cnpj}
      />
      <DeleteMessageWithSuspense
        loadComponent={showDeleteConfirm}
        showModal={showDeleteConfirm}
        setShowModal={setShowDeleteConfirm}
        onConfirm={deleteMessage}
        emitterName={enterpriseName ?? name}
      />
    </>
  );
};

export default memo(ChatRoomContainer);
