import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "../store/ducks/financialSubscriptions";
import { LayoutActions } from "store/ducks/layout";
import { Span } from "components/core/Typography";
import { Trans } from "react-i18next";
import useItems from "./useItems";

const useFinancialSubscription = () => {
  const dispatch = useDispatch();

  const {
    availablePaymentForms,
    chargeInvoiceResponse,
    error,
    exportFile,
    invoiceProtheus,
    invoicesToCharge,
    newSubscriptionParams,
    requestStatus,
    showChargeModal,
    availableBillingPeriods,
    billingsFrom,
    simulatedSubscription,
    subscription,
    subscriptionCoupon,
    subscriptionInvoice,
    subscriptionInvoiceItems,
    subscriptionInvoices,
    subscriptionItem,
    subscriptionItems,
    subscriptionPrice,
    subscriptions,
    enterpriseSubscriptions,
  } = useSelector(({ financialSubscriptions }) => financialSubscriptions);

  const subscriptionsUseItems = useItems(subscriptions.items);
  const subscriptionItemsUseItems = useItems(subscriptionItems.items);
  const subscriptionInvoicesUseInvoices = useItems(subscriptionInvoices.items);

  const dispatchConfirmation = (text, type, title) =>
    dispatch(
      LayoutActions.showConfirmationModal({
        content: <Span>{text}</Span>,
        type,
        title,
      }),
    );

  const handleOnResolved = (i18nKey, callback, info) => {
    dispatchConfirmation(<Trans i18nKey={i18nKey} values={{ info }} />, "success");
    if (callback) callback();
  };

  const handleOnRejected = (i18nKey, errorMsg, callback) => {
    dispatchConfirmation(
      <Trans i18nKey={i18nKey} values={{ errorMsg }} components={{ bold: <strong /> }} />,
      "error",
    );
    if (callback) callback();
  };

  const validationErrors = () => {
    let errors = "";
    if (!error?.validation) return error?.message;
    const errorKeys = error?.validation ? Object.keys(error?.validation) : [];
    if (errorKeys.length > 0) {
      const validationKeys = Object.keys(error?.validation);
      const mappedErrors = validationKeys.map((key) => error.validation[key]);
      errors = mappedErrors.join("<br/>");
    } else if (error?.message) {
      errors = error?.message;
    }
    return errors;
  };

  const cleanState = () => dispatch(Actions.cleanState());
  const resetChargeStates = () => dispatch(Actions.resetChargeStates());
  const resetState = (params) => dispatch(Actions.resetState(params));
  const resetRequestState = (requestName) => dispatch(Actions.resetRequestState(requestName));
  const billSubscription = (params) => dispatch(Actions.billSubscription(params));
  const cancelInvoice = (params) => dispatch(Actions.cancelInvoice(params));
  const chargeInvoice = (params) => dispatch(Actions.chargeFinancialSubscriptionInvoice(params));
  const createSubscription = (params) => dispatch(Actions.createFinancialSubscription(params));
  const deleteSubscriptionItem = (params) =>
    dispatch(Actions.deleteFinancialSubscriptionItem(params));
  const deleteSubscriptionItems = (params) =>
    dispatch(Actions.deleteFinancialSubscriptionItems(params));
  const downloadFile = (filename) => dispatch(Actions.downloadFile(filename));
  const exportSubscriptions = (params) => dispatch(Actions.exportSubscriptions(params));
  const getAvailableInvoicesToCharge = (params) =>
    dispatch(Actions.getFinancialSubscriptionInvoicesToCharge(params));
  const getAvailablePaymentForms = (params) =>
    dispatch(Actions.getFinancialSubscriptionPaymentForms(params));
  const getCouponForSubscription = (couponCode) =>
    dispatch(Actions.getCouponForSubscription(couponCode));
  const getSubscription = (id) => dispatch(Actions.getFinancialSubscription(id));
  const getAvailableBillingPeriods = (id) =>
    dispatch(Actions.getFinancialSubscriptionAvailableBillingPeriods(id));
  const getBillingsFrom = (id) => dispatch(Actions.getFinancialSubscriptionBillingsFrom(id));
  const getEnterpriseSubscriptions = (enterpriseId) =>
    dispatch(Actions.getFinancialEnterpriseSubscriptions(enterpriseId));
  const getSubscriptionInvoice = (params) =>
    dispatch(Actions.getFinancialSubscriptionInvoice(params));
  const getSubscriptionInvoiceCharges = (params) =>
    dispatch(Actions.getFinancialSubscriptionInvoiceCharges(params));
  const getSubscriptionInvoiceItems = (params) =>
    dispatch(Actions.getFinancialSubscriptionInvoiceItems(params));
  const getSubscriptionInvoices = (params) =>
    dispatch(Actions.getFinancialSubscriptionInvoices(params));
  const getSubscriptionItems = (params) => dispatch(Actions.getFinancialSubscriptionItems(params));
  const getSubscriptionItemsByCycles = (params) =>
    dispatch(Actions.getFinancialSubscriptionItemsByCycles(params));
  const getSubscriptions = (params) => dispatch(Actions.getFinancialSubscriptions(params));
  const getNewSubscriptionParams = (emitterIdOrCNPJ) =>
    dispatch(Actions.getNewFinancialSubscriptionParams(emitterIdOrCNPJ));
  const saveSubscription = (params) => dispatch(Actions.saveFinancialSubscription(params));
  const saveSubscriptionItem = (params) => dispatch(Actions.saveFinancialSubscriptionItem(params));
  const sendInvoiceMail = (params) => dispatch(Actions.sendFinancialInvoiceMail(params));
  const sendInvoiceToProtheus = (params) => dispatch(Actions.sendInvoiceToProtheus(params));
  const toggleChargeModal = () => dispatch(Actions.toggleChargeModalState());
  const simulateSubscription = (params) => dispatch(Actions.simulateFinancialSubscription(params));

  return {
    availableBillingPeriods,
    billSubscription,
    availablePaymentForms,
    cancelInvoice,
    chargeInvoice,
    chargeInvoiceResponse,
    cleanState,
    createSubscription,
    deleteSubscriptionItem,
    deleteSubscriptionItems,
    dispatch,
    dispatchConfirmation,
    downloadFile,
    enterpriseSubscriptions,
    error,
    exportFile,
    exportSubscriptions,
    invoiceProtheus,
    invoicesToCharge,
    getAvailableInvoicesToCharge,
    getAvailablePaymentForms,
    getCouponForSubscription,
    getAvailableBillingPeriods,
    getBillingsFrom,
    getEnterpriseSubscriptions,
    getSubscription,
    getSubscriptionInvoice,
    getSubscriptionInvoiceCharges,
    getSubscriptionInvoiceItems,
    getSubscriptionInvoices,
    getSubscriptionItems,
    getSubscriptionItemsByCycles,
    getSubscriptions,
    getNewSubscriptionParams,
    handleOnRejected,
    handleOnResolved,
    billingsFrom,
    newSubscriptionParams,
    requestStatus,
    resetRequestState,
    resetChargeStates,
    resetState,
    saveSubscription,
    saveSubscriptionItem,
    sendInvoiceMail,
    sendInvoiceToProtheus,
    simulatedSubscription,
    simulateSubscription,
    showChargeModal,
    simulatedSubscription,
    simulateSubscription,
    subscription,
    subscriptionCoupon,
    subscriptionInvoice,
    subscriptionInvoiceItems,
    subscriptionInvoices,
    subscriptionItem,
    subscriptionItems,
    subscriptionInvoicesUseInvoices,
    subscriptionItemsUseItems,
    subscriptionPrice,
    subscriptionsUseItems,
    subscriptions,
    toggleChargeModal,
    validationErrors,
  };
};

export default useFinancialSubscription;
