import { createActions, createReducer } from "reduxsauce";
import {
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
  REQUEST_RESOLVED,
  REQUEST_REJECTED,
} from "utils/constants";

export const { Types: ChatTypes, Creators: ChatActions } = createActions({
  fetchChats: ["params", "dispatchByService"],
  fetchChatsSuccess: ["payload"],
  fetchTotalChatsUnread: null,
  fetchTotalChatsUnreadSuccess: ["total"],
  startChatRoom: ["chatRoom", "messageOnLoad"],
  readChatById: ["id"],
  readChatByIdSuccess: ["id"],
  deleteMessageById: ["id"],
  deleteMessageByIdSuccess: ["payload"],
  sendAttachment: ["attachment", "params"],
  sendAttachmentSuccess: ["payload"],
  requestFailure: ["error", "originalType"],
  cleanupChats: null,
});

export const INITIAL_STATE = {
  chats: {},
  totalChatsUnread: 0,
  chatRoom: {},
  ChatRequests: {
    [ChatTypes.FETCH_CHATS]: REQUEST_NOT_STARTED,
    [ChatTypes.READ_CHAT_BY_ID]: REQUEST_NOT_STARTED,
    [ChatTypes.SEND_ATTACHMENT]: REQUEST_NOT_STARTED,
  },
};

const fetchChats = (state, { dispatchByService }) => ({
  ...state,
  ChatRequests: {
    ...state.ChatRequests,
    [ChatTypes.FETCH_CHATS]: dispatchByService ? REQUEST_RESOLVED : REQUEST_PENDING,
  },
});

const fetchChatsSuccess = (state, { payload }) => ({
  ...state,
  chats: payload,
  ChatRequests: {
    ...state.ChatRequests,
    [ChatTypes.FETCH_CHATS]: REQUEST_RESOLVED,
  },
});

const fetchTotalChatsUnread = (state) => ({
  ...state,
  ChatRequests: {
    ...state.ChatRequests,
    [ChatTypes.FETCH_TOTAL_CHATS_UNREAD]: REQUEST_PENDING,
  },
});

const fetchTotalChatsUnreadSuccess = (state, { total }) => ({
  ...state,
  totalChatsUnread: total,
  ChatRequests: {
    ...state.ChatRequests,
    [ChatTypes.FETCH_TOTAL_CHATS_UNREAD]: REQUEST_RESOLVED,
  },
});

const startChatRoom = (state, { chatRoom, messageOnLoad }) => ({
  ...state,
  chatRoom,
  messageOnLoad,
});

const readChatById = (state) => ({
  ...state,
  ChatRequests: {
    ...state.ChatRequests,
    [ChatTypes.READ_CHAT_BY_ID]: REQUEST_PENDING,
  },
});

const readChatByIdSuccess = (state, { id }) => ({
  ...state,
  chats: state.chats.map(({ idChat, unread, ...chat }) => ({
    ...chat,
    idChat,
    unread: idChat === id ? "0" : unread,
  })),
  ChatRequests: {
    ...state.ChatRequests,
    [ChatTypes.READ_CHAT_BY_ID]: REQUEST_RESOLVED,
  },
});

const deleteMessageById = (state) => ({
  ...state,
  ChatRequests: {
    ...state.ChatRequests,
    [ChatTypes.DELETE_MESSAGE_BY_ID]: REQUEST_PENDING,
  },
});

const deleteMessageByIdSuccess = (state) => ({
  ...state,
  ChatRequests: {
    ...state.ChatRequests,
    [ChatTypes.DELETE_MESSAGE_BY_ID]: REQUEST_RESOLVED,
  },
});

const sendAttachment = (state) => ({
  ...state,
  ChatRequests: {
    ...state.ChatRequests,
    [ChatTypes.SEND_ATTACHMENT]: REQUEST_PENDING,
  },
});

const sendAttachmentSuccess = (state) => ({
  ...state,
  ChatRequests: {
    ...state.ChatRequests,
    [ChatTypes.SEND_ATTACHMENT]: REQUEST_RESOLVED,
  },
});

const requestFailure = (state, { error, originalType }) => ({
  ...state,
  error,
  ChatRequests: {
    ...state.ChatRequests,
    [originalType]: REQUEST_REJECTED,
  },
});

const cleanupChats = (state) => ({
  ...INITIAL_STATE,
  totalChatsUnread: state.totalChatsUnread,
});

export default createReducer(INITIAL_STATE, {
  [ChatTypes.FETCH_CHATS]: fetchChats,
  [ChatTypes.FETCH_CHATS_SUCCESS]: fetchChatsSuccess,
  [ChatTypes.FETCH_TOTAL_CHATS_UNREAD]: fetchTotalChatsUnread,
  [ChatTypes.FETCH_TOTAL_CHATS_UNREAD_SUCCESS]: fetchTotalChatsUnreadSuccess,
  [ChatTypes.START_CHAT_ROOM]: startChatRoom,
  [ChatTypes.READ_CHAT_BY_ID]: readChatById,
  [ChatTypes.READ_CHAT_BY_ID_SUCCESS]: readChatByIdSuccess,
  [ChatTypes.DELETE_MESSAGE_BY_ID]: deleteMessageById,
  [ChatTypes.DELETE_MESSAGE_BY_ID_SUCCESS]: deleteMessageByIdSuccess,
  [ChatTypes.SEND_ATTACHMENT]: sendAttachment,
  [ChatTypes.SEND_ATTACHMENT_SUCCESS]: sendAttachmentSuccess,
  [ChatTypes.REQUEST_FAILURE]: requestFailure,
  [ChatTypes.CLEANUP_CHATS]: cleanupChats,
});
