import { E } from "caniuse-lite/data/agents";
import styled from "styled-components";
import media from "styled-media-query";

// export const ContainerMain = styled.div`
//   /* border-bottom: 3px solid #0c8dff;
//   border-top: 25px solid #0c8dff; */

//   .
// `;

export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const ContainerHeader = styled.div`
  font-weight: bold;
  font-size: 12px;
  padding: 10px 17px 4px 13px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  position: relative;
  line-height: 22px;

  :before {
    content: "";
    position: absolute;
    bottom: 110px;
    right: 0;
    width: 0;
    height: 0;
    background: transparent;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(180deg);
    border-style: solid;
    border-width: 0px 0 24px 745px;
    border-color: transparent transparent #0c8dff transparent;
  }

  ::after {
    content: "";
    position: absolute;
    bottom: -26px;
    right: 0;
    width: 0;
    height: 0;
    background: transparent;
    transform: rotate(0);
    border-style: solid;
    border-width: 0px 0 27px 706px;
    border-color: transparent transparent #0c8dff transparent;
  }
`;

export const ContainerMain = styled.div``;

export const ContainerData = styled.div`
  padding-top: 10px;
`;

export const Image = styled.img`
  width: 150px;
  height: 100px;
`;

export const ContainerTable = styled.div`
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  tr {
    text-align: center !important;
  }

  hr {
    width: 250%;
    margin-top: 10px;
  }

  .tdFooter {
    text-align: right;
    font-weight: bold;
  }

  p {
    font-size: 13px;
    margin-top: 10rem;
    font-weight: bold;
  }
`;

export const ContainerFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
`;
