import React, { useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Button from "components/core/Button";
import { InputHidden } from "components/core/Form";
import MessageParser from "components/core/MessageParser";
import { Span } from "components/core/Typography";
import { formatDate, todayIsTheDay } from "helpers";
import { eventKeys } from "utils/constants";
import { ReactComponent as IconDownload } from "assets/icons/icon-download.svg";
import { ReactComponent as IconPin } from "assets/icons/icon-pin.svg";
import * as S from "./styled";

const DateGroupingBar = ({ currentItemDate, nextItem }) => {
  if (todayIsTheDay(currentItemDate) || !nextItem) return null;

  const nextItemDate = formatDate(nextItem.createdAt, 0, false);
  const sameDate = nextItemDate === formatDate(currentItemDate);

  return sameDate ? null : <S.DateBar>{nextItemDate}</S.DateBar>;
};

const MessageMenu = ({ handleDelete, deleteText, received }) => {
  const [open, setOpen] = useState(false);

  if (received) {
    return null;
  }

  return (
    <>
      <S.OpenMessageMenu type="button" onClick={() => setOpen(!open)} open={open} />
      <S.MessageMenu open={open}>
        <S.Delete onClick={handleDelete}>{deleteText}</S.Delete>
      </S.MessageMenu>
    </>
  );
};

const Chat = ({
  messages,
  sendMessage,
  handleSendAttachment,
  handlePinEmitter,
  lastMessageRef,
  firstMessageRef,
  emitterName,
  cnpj,
}) => {
  const { t: translate } = useTranslation();
  const inputNewMessageRef = useRef();
  const inputAttachmentRef = useRef();
  const inputFileForm = useRef();
  const [attach, setAttach] = useState();
  const [lastKeyWasShift, setLastKeyWasShift] = useState(false);

  const messagesRef = useCallback((node) => {
    const messagesOverlay = node;

    if (node) {
      messagesOverlay.scrollTop = node.scrollHeight;
    }
  }, []);

  const handleAttachmentChange = ({ target: { files } }) => {
    const [attachment] = files;
    setAttach(attachment);
  };

  const handleRemoveAttach = () => {
    setAttach(null);
    inputFileForm.current.reset();
  };

  const submitNewMessage = () => {
    const { innerText } = inputNewMessageRef?.current ?? {};

    if (attach) {
      handleSendAttachment(attach);
      handleRemoveAttach();
      return;
    }

    if (innerText) {
      sendMessage(innerText);
      inputNewMessageRef.current.innerText = "";
    }
  };

  const handleOnKeyDown = (event) => {
    const { key } = event;
    setLastKeyWasShift(key === eventKeys.shift);

    if (key !== eventKeys.enter || lastKeyWasShift) return;

    event.preventDefault();
    submitNewMessage();
  };

  const getRefByIndex = (index) => {
    if (index === 0) return firstMessageRef;

    return index === messages.length - 1 ? lastMessageRef : null;
  };

  return (
    <S.ChatWrapper>
      <S.ChatHeader>
        <div>
          <S.EnterpriseName mb="0" data-testid="enterpriseName">
            {emitterName}
          </S.EnterpriseName>
          <S.Subtext data-testid="cnpj">{cnpj ?? "-"}</S.Subtext>
        </div>
        <Button handleClick={handlePinEmitter} variant="outline">
          <IconPin />
          <span>{translate("pinEmitter")}</span>
        </Button>
      </S.ChatHeader>
      <S.MessagesOverlay ref={messagesRef}>
        <S.Messages>
          {messages.map(({ message, received, createdAt, file }, index) => (
            <React.Fragment key={index}>
              <DateGroupingBar currentItemDate={createdAt} nextItem={messages[index - 1]} />
              <S.Message ref={getRefByIndex(index)} received={received} hasFile={!!file}>
                {file ? (
                  <S.MessageFile>
                    {file.name}
                    <a href={file.url} download title={translate("download")}>
                      <IconDownload />
                    </a>
                  </S.MessageFile>
                ) : (
                  <MessageParser message={message} />
                )}
                <S.Date>{formatDate(createdAt, 0, true, true)}</S.Date>
                {/*
                --hide delete button--
                <MessageMenu
                  handleDelete={() => handleDeleteMessage(id)}
                  deleteText={translate("delete")}
                  received={received}
                /> */}
              </S.Message>
            </React.Fragment>
          ))}
        </S.Messages>
      </S.MessagesOverlay>
      <S.ChatActions>
        <S.ButtonAttach htmlFor="addAttachment" title={translate("sendAttachment")} />
        <form ref={inputFileForm}>
          <InputHidden
            type="file"
            id="addAttachment"
            name="addAttachment"
            ref={inputAttachmentRef}
            onChange={handleAttachmentChange}
          />
        </form>
        <S.NewMessageWrapper>
          {!attach?.name ? (
            <>
              <S.NewMessage
                ref={inputNewMessageRef}
                contentEditable={!attach}
                onKeyDown={handleOnKeyDown}
                role="textbox"
                aria-multiline="true"
                aria-labelledby="typeYourMessageHere"
                aria-required="true"
              />
              <span>{translate("typeYourMessageHere")}</span>
            </>
          ) : (
            <S.AttachDisplay>
              <Span>{attach.name}</Span>
              <S.ButtonRemoveAttach onClick={handleRemoveAttach} />
            </S.AttachDisplay>
          )}
        </S.NewMessageWrapper>
        <Button handleClick={submitNewMessage}>{translate("send")}</Button>
      </S.ChatActions>
    </S.ChatWrapper>
  );
};

DateGroupingBar.propTypes = {
  currentItemDate: PropTypes.string.isRequired,
  nextItem: PropTypes.object,
};

DateGroupingBar.defaultProps = {
  nextItem: null,
};

MessageMenu.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  deleteText: PropTypes.string.isRequired,
  received: PropTypes.bool.isRequired,
};

Chat.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      message: PropTypes.string,
      received: PropTypes.bool,
      createdAt: PropTypes.string,
      file: PropTypes.object,
    }),
  ),
  sendMessage: PropTypes.func.isRequired,
  handleSendAttachment: PropTypes.func.isRequired,
  handlePinEmitter: PropTypes.func.isRequired,
  lastMessageRef: PropTypes.func.isRequired,
  firstMessageRef: PropTypes.func.isRequired,
  emitterName: PropTypes.string.isRequired,
  cnpj: PropTypes.string,
};

Chat.defaultProps = {
  messages: [],
  cnpj: "",
};

export default Chat;
