import React from "react";
import { Table, RenderHeaders, TableBody, TableHeader, TableItem } from "components/core/Table";
import { Modal, Span } from "components/core";
import PropTypes from "prop-types";
import WithEmptyFeedback from "components/core/WithEmptyFeedback";
import { formatDate, formatNumberToReal } from "helpers";
import { useTranslation } from "react-i18next";
import StatusDot, { StatusTypes } from "components/core/StatusDot";
import { Spinner, SpinnerOverlay } from "components/core/WithSpinner/styled";
import Pagination from "components/core/Pagination";
import * as S from "./styled";

const ListLeads = ({
  items,
  statusRequest,
  params,
  total,
  totalPages,
  page,
  perPage,
  orderBy,
  order,
  // showModalEmail,
  showConfirmModal,
  setShowConfirmModal,
  setDataLead,
  setShowEditForm,
  requestApiInter,
  sendEmail,
}) => {
  const { t: translate } = useTranslation();
  const headers = [
    { slug: "name", title: "Nome" },
    { slug: "email", title: "Email" },
    { slug: "phoneNumber", title: "Telefone" },
    // { slug: "statusEmail", title: "Status Email" },
    { slug: "enterpriseState", title: "Estado" },
    { slug: "active", title: "Cadastro?" },
    { slug: "paymentGatewayPlanTypeId", title: "Plano" },
    { slug: "amount", title: "Valor" },
    { slug: "valorTotal", title: "Valor Total" },
    { slug: "status", title: "Pago?" },
    { slug: "paymentDate", title: "Data Pagamento" },
    { slug: "checkoutInter", title: "Checkout Inter?" },
    { slug: "", title: "Ações" },
  ];

  items.forEach((item) => {
    if (item.phoneNumber.length === 10) {
      item.phoneNumberFormated = `(${item.phoneNumber.substring(
        0,
        2,
      )}) ${item.phoneNumber.substring(2, 6)}-${item.phoneNumber.substring(6, 10)}`;
    }
    if (item.phoneNumber.length === 11) {
      item.phoneNumberFormated = `(${item.phoneNumber.substring(
        0,
        2,
      )}) ${item.phoneNumber.substring(2, 3)} ${item.phoneNumber.substring(
        3,
        7,
      )}-${item.phoneNumber.substring(7, 12)}`;
    }
    if (item.paymentGatewayPlanTypeId === 1) {
      item.totalValue = item.amount * 12;
    }
  });

  // const getStatusColor = (status) => {
  //   switch (status) {
  //     case 0:
  //       return StatusTypes.WARNING;
  //     case 1:
  //       return StatusTypes.SUCCESS;
  //     default:
  //       return StatusTypes.WARNING;
  //   }
  // };
  // const getValueStatus = (status) => {
  //   switch (status) {
  //     case 0:
  //       return "Não Enviado";
  //     case 1:
  //       return "Enviado";
  //     default:
  //       return "Não Enviado";
  //   }
  // };
  const getPaymentStatusColor = (status) => {
    switch (status) {
      case "paid":
        return StatusTypes.SUCCESS;
      case "refunded":
        return StatusTypes.WARNING;
      case "canceled":
        return StatusTypes.ERROR;
      default:
        return null;
    }
  };
  const getCheckoutStatusColor = (status) => {
    switch (status) {
      case "Realizado":
        return StatusTypes.SUCCESS;
      case "Aguardando":
        return StatusTypes.WARNING;
      case "Não":
        return StatusTypes.PROCESSING;
      case "Cancelado":
        return StatusTypes.ERROR;
      default:
        return null;
    }
  };

  const getCadastroStatus = (status) => {
    switch (status) {
      case false:
        return "Não";
      case true:
        return "Sim";
      default:
        return "Não";
    }
  };
  const getPlanName = (id) => {
    switch (id) {
      case 1:
        return "Plano Mensal";
      case 2:
        return "Plano Anual";
      case 3:
        return "Plano Mensal";
      case 4:
        return "Plano Anual";
      case 5:
        return "Plano Bienal";
      default:
        return "-";
    }
  };

  const requestPending = (status) => {
    return status === "pending" || status === undefined;
  };

  return requestPending(statusRequest) ? (
    <SpinnerOverlay>
      <Spinner />
    </SpinnerOverlay>
  ) : (
    <>
      <Table>
        <TableHeader>
          <RenderHeaders
            headers={headers}
            setOrderBy={params.setOrderBy}
            orderBy={orderBy}
            order={order}
          />
        </TableHeader>
        <TableBody>
          {items.map(
            ({
              id,
              name,
              email,
              phoneNumber,
              phoneNumberFormated,
              enterpriseState,
              active,
              paymentDate,
              paymentGatewayPlanTypeId,
              amount,
              totalValue,
              status,
              checkoutInter,
              interClickRef,
              statusEmail,
              createdAt,
              paymentGatewayClientSubscriptionInvoiceId: orderRef,
            }) => {
              return (
                <tr key={id}>
                  <TableItem>
                    <Span>{name}</Span>
                  </TableItem>
                  <TableItem>
                    <Span>{email}</Span>
                  </TableItem>
                  <TableItem>
                    <Span>{phoneNumberFormated}</Span>
                  </TableItem>
                  {/* <TableItem>
                    <Span>
                      <StatusDot type={getStatusColor(statusEmail)} />
                      {getValueStatus(statusEmail)}
                    </Span>
                  </TableItem> */}
                  <TableItem>
                    <Span>{enterpriseState}</Span>
                  </TableItem>
                  <TableItem>
                    <Span>{getCadastroStatus(active)}</Span>
                  </TableItem>
                  <TableItem>
                    <Span>{getPlanName(paymentGatewayPlanTypeId)}</Span>
                  </TableItem>
                  <TableItem>
                    <Span>{formatNumberToReal(amount)}</Span>
                  </TableItem>
                  <TableItem>
                    <Span>{formatNumberToReal(totalValue)}</Span>
                  </TableItem>
                  <TableItem>
                    <Span>
                      <StatusDot type={getPaymentStatusColor(status)} />
                      {translate(status)}
                    </Span>
                  </TableItem>
                  <TableItem>
                    <Span>{paymentDate ? formatDate(paymentDate) : null}</Span>
                  </TableItem>
                  <TableItem>
                    <Span>
                      <StatusDot type={getCheckoutStatusColor(checkoutInter)} />
                      {checkoutInter}
                    </Span>
                  </TableItem>
                  <TableItem>
                    <S.ActionDiv>
                      {status === "paid" && checkoutInter === "Não" && (
                        <S.IconPending
                          title="Informar Venda"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            requestApiInter({
                              id,
                              action: "notify",
                              createdAt,
                              status: "pending",
                              amount,
                              currency: "BRL",
                              interClickRef,
                              orderRef: orderRef.toString(),
                              productDescription: getPlanName(paymentGatewayPlanTypeId),
                            });
                          }}
                        />
                      )}
                      {status === "paid" && checkoutInter === "Aguardando" && (
                        <S.IconCheckout
                          title="Checkout Inter"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            requestApiInter({
                              id,
                              action: "confirm",
                              createdAt,
                              status: "confirmed",
                              amount,
                              currency: "BRL",
                              interClickRef,
                              orderRef: orderRef.toString(),
                              productDescription: getPlanName(paymentGatewayPlanTypeId),
                            });
                          }}
                        />
                      )}
                      {checkoutInter === "Aguardando" && (
                        <S.IconCancel
                          title="Cancelar Checkout"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            requestApiInter({
                              id,
                              action: "decline",
                              createdAt,
                              status: "declined",
                              amount,
                              currency: "BRL",
                              interClickRef,
                              orderRef: orderRef.toString(),
                              productDescription: getPlanName(paymentGatewayPlanTypeId),
                            });
                          }}
                        />
                      )}
                      {/* <S.IconEmail
                        title="Enviar Email"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          showModalEmail({ id, name, email, interClickRef, statusEmail });
                        }}
                      /> */}
                      {checkoutInter !== "Realizado" && checkoutInter !== "Cancelado" && (
                        <S.IconEdit
                          title="Editar"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setDataLead({ id, email, phoneNumber });
                            setShowEditForm(true);
                          }}
                        />
                      )}
                      {checkoutInter !== "Realizado" && checkoutInter !== "Cancelado" && (
                        <S.IconCopy
                          title="Copiar Link"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `https://admin.obviacontabil.com/novo-cadastro/new-user/?interClickRef=${interClickRef}`,
                            );
                          }}
                        />
                      )}
                    </S.ActionDiv>
                  </TableItem>
                </tr>
              );
            },
          )}
        </TableBody>
      </Table>
      <Pagination
        perPage={perPage}
        totalItems={total}
        actualPage={page}
        lastPage={totalPages}
        setPage={params.setPage}
      />
      <Modal
        title="Deseja confirmar o envio do email?"
        confirmLabel="Enviar"
        onCloseModal={() => setShowConfirmModal(false)}
        onConfirmModal={sendEmail}
        closeOnConfirm
        showModal={showConfirmModal}
        cancelLabel="Fechar"
        width="25%"
      >
        <Span>O envio pode demorar alguns segundos,espere a confirmação de envio!</Span>
      </Modal>
    </>
  );
};

ListLeads.propTypes = {
  items: PropTypes.array,
  params: PropTypes.object.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
};

ListLeads.defaultProps = {
  items: [],
  total: 0,
  totalPages: 0,
  page: 1,
  perPage: 10,
};

export default WithEmptyFeedback(ListLeads);
