import { put, takeLatest, cancelled } from "redux-saga/effects";
import axios from "axios";
import api from "services/api";
import { ChatActions, ChatTypes } from "store/ducks/chat";
import {
  CHATS,
  GET,
  READ_CHAT_BY_ID,
  PUT,
  MESSAGE_BY_ID,
  DELETE,
  CHAT_DOCUMENTS,
  POST,
} from "utils/constants";

const { CancelToken } = axios;

function* fetchChats({ params, type }) {
  const source = CancelToken.source();

  try {
    const { data } = yield api({
      method: GET,
      url: CHATS,
      params,
      cancelToken: source.token,
    });
    yield put(ChatActions.fetchChatsSuccess(data || []));
  } catch (error) {
    yield put(ChatActions.requestFailure(error, type));
  } finally {
    if (yield cancelled()) {
      source.cancel();
    }
  }
}

function* readChatById({ id, type }) {
  try {
    if (Number(id)) {
      yield api({
        method: PUT,
        url: READ_CHAT_BY_ID(id),
      });
      yield put(ChatActions.readChatByIdSuccess(id));
    }
  } catch (error) {
    yield put(ChatActions.requestFailure(error, type));
  }
}

function* deleteMessageById({ id, type }) {
  try {
    if (Number(id)) {
      yield api({
        method: DELETE,
        url: MESSAGE_BY_ID(id),
      });
      yield put(ChatActions.deleteMessageByIdSuccess(id));
    }
  } catch (error) {
    yield put(ChatActions.requestFailure(error, type));
  }
}

function* sendAttachment({ attachment, params, type }) {
  try {
    api.defaults.headers.common["Content-Type"] = "multipart/form-data";
    const { data } = yield api({
      method: POST,
      url: CHAT_DOCUMENTS,
      data: attachment,
      params,
    });
    yield put(ChatActions.sendAttachmentSuccess(data));
  } catch (error) {
    const failure = {
      response: error?.response?.data ?? {
        data: {
          message: "request-entity-too-large",
        },
      },
    };
    yield put(ChatActions.requestFailure(failure, type));
  }
}

export function* watchSagas() {
  yield takeLatest(ChatTypes.FETCH_CHATS, fetchChats);
  yield takeLatest(ChatTypes.READ_CHAT_BY_ID, readChatById);
  yield takeLatest(ChatTypes.DELETE_MESSAGE_BY_ID, deleteMessageById);
  yield takeLatest(ChatTypes.SEND_ATTACHMENT, sendAttachment);
}
